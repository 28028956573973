import React, { useState, useContext, useEffect } from 'react';
import AuthContext from '../contexts/AuthContext';
import { Route, Redirect } from 'react-router';
import OrganizationContext from '../contexts/OrganizationContext';
import { ToastContext } from '@laerdal/life-react-components';
import { useTranslation } from 'react-i18next';
import toastOptions from '../assets/ToastOptions';
import { getSubscriptionStatus } from '../services/functions';

interface PrivateRouteProps {
  component: React.ElementType;
  path: string;
  exact?: boolean;
  needsSubscription?: boolean;
  notForTrialUsers?: boolean;
  notForProUsers?: boolean;
  needsActiveSubscription?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
                                                     component: Component,
                                                     needsSubscription,
                                                     notForProUsers,
                                                     notForTrialUsers,
                                                     needsActiveSubscription,
                                                     ...rest
                                                   }) => {
  const [status, setStatus] = useState('loading');
  const { isLoggedIn, setIntent } = useContext(AuthContext);
  const { organization, setOrgLoad } = useContext(OrganizationContext);
  const { addToast } = useContext(ToastContext);
  const { t } = useTranslation('CreateOrganization');

  const grantOrDenyAccess = (typeOfSubscription: string | null) => {
    if (!typeOfSubscription && needsSubscription) {
      addToast(t('You need a subscription to access this page'), toastOptions.error);
      return setStatus('toPlansAndPricing');
    }
    if (typeOfSubscription === 'trial' && notForTrialUsers && !organization.subscription.organizationHasBeenUpdated) {
      addToast(t('Trial users cannot access this page'), toastOptions.error);
      return setStatus('toAdminPage');
    }
    if (typeOfSubscription === 'pro' && notForProUsers && !organization.subscription.organizationHasBeenUpdated) {
      addToast(t('Pro users cannot access this page'), toastOptions.error);
      return setStatus('toAdminPage');
    }
    if (typeOfSubscription === 'pro' && needsActiveSubscription && !organization.subscription.isExpired && !organization.subscription.organizationHasBeenUpdated) {
      addToast(t('You do not have access to this page'), toastOptions.error);
      return setStatus('toAdminPage');
    }
    return setStatus('hasAccess');
  };

  useEffect(() => {
    const determineAccess = async () => {
      try {
        await isLoggedIn();
      } catch {
        addToast(t('You do not have access to this page, please sign in'), toastOptions.error);
        return setStatus('toLogin');
      }
      //Login successful and no restrictions (such as needsSubscription) detected. Grant access.
      if (!needsSubscription && !notForTrialUsers && !notForProUsers) return setStatus('hasAccess');
      try {
        if (organization.domain !== '' || notForProUsers) {
          const subscriptionStatus = await getSubscriptionStatus(organization);
          setOrgLoad(false);
          grantOrDenyAccess(subscriptionStatus);
        }
      } catch {
        addToast(t('Could not load your subscription info'), toastOptions.error);
        return setStatus('toPlansAndPricing');
      }
    };
    determineAccess();
  }, [organization]);

  setIntent(rest.path as string);

  if (status === 'loading') {
    return null;
  }

  //Check if component is undefined. Although this should never be the case, this is necassary for Typescript.
  if (!Component) return null;

  return (
    // @ts-ignore
    <Route
      {...rest}
      render={(props:any) => {
        if (status === 'hasAccess') {
          // @ts-ignore
          return <Component {...props} />;
        } else if (status === 'toAdminPage') {
          return <Redirect to="/sign-in" />;
        } else if (status === 'toPlansAndPricing') {
          return <Redirect to="/plans-and-pricing" />;
        } else {
          return <Redirect to="/sign-in" />;
        }
      }}
    />
  );
};

export default PrivateRoute;
