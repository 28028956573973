import React, { useState } from 'react';
import styled from 'styled-components';
import { ChevronDown, COLORS } from '@laerdal/life-react-components';
import { Button } from '@laerdal/life-react-components';
import { LandingHero } from '../../typings/sanity';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import { modalWide } from '../../styles/modalStyles';

const HeroWrapper = styled.header`
  border-bottom: 1px solid ${COLORS.neutral_300};
  width: 100%;
  height: 92vh;
  margin-bottom: 70px;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
  & svg {
    margin-top: auto;
    position: absolute;
    bottom: 0;
    transform: translate(-50%, -50%);
    left: 50%;
  }
`;

const HeroTextContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 700px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  & h1 {
    color: ${COLORS.white};
    font-weight: 700;
    font-size: 48px;
    margin-top: 0;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  > :nth-child(1) {
    margin-right: 20px;
  }
`;

const BackgroundVideo = styled.video`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /*The following properties makes a video with aspect ratio 16/9 act like it has the background-size: cover property*/
  height: 100%;
  width: 177.77777778vh; /* 100 * 16 / 9 */
  min-width: 100%;
  min-height: 56.25vw; /* 100 * 9 / 16 */
`;

interface HeroProps {
  content: LandingHero;
}

const Hero = ({ content }: HeroProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const history = useHistory();
  return (
    <HeroWrapper>
      <Modal style={modalWide} isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
        <iframe
          width="100%"
          height="500px"
          src={content.youtubeVideoURL}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen>
          Could not load
        </iframe>
      </Modal>
      <BackgroundVideo autoPlay muted loop src={content.backgroundVideoURL} />
      <HeroTextContent>
        <h1>{content.title}</h1>
        <ButtonWrapper>
          <Button size="big" onClick={() => history.push(content.primaryButton.buttonURL)}>
            {content.primaryButton.buttonText}
          </Button>
          <Button size="big" variant="tertiary" onClick={() => setModalIsOpen(true)}>
            {content.secondaryButton}
          </Button>
        </ButtonWrapper>
      </HeroTextContent>
      <ChevronDown color={COLORS.neutral_20} size={'100px'} />
    </HeroWrapper>
  );
};

export default Hero;
