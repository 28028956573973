import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { COLORS } from '@laerdal/life-react-components';

const SubscriptionDescriptionWrapper = styled.div`
  padding: 0 24px;
`;

const TopText = styled.p`
  margin: 10px 0 15px 0;
  font-size: 20px;
  font-weight: 700;
  color: ${COLORS.neutral_700};
`;

const BottomText = styled.p`
  margin: 0;
`;

const GreenText = styled.span`
  color: ${COLORS.primary_500};
`;

interface SubscriptionDescriptionProps {
  subscriptionPickerTextFromSanity: string;
  freeTrial?: boolean;
  instructorCount: number;
}

const SubscriptionDescription = ({ subscriptionPickerTextFromSanity, freeTrial, instructorCount }: SubscriptionDescriptionProps) => {
  const { t } = useTranslation('CreateOrganization');
  return (
    <SubscriptionDescriptionWrapper>
      <TopText>
        {freeTrial ? t('Trial access for up to') : t('TeamReporter app for')} <GreenText>{instructorCount}</GreenText> {instructorCount > 1 ? t('instructors') : t('instructor')}
      </TopText>
      <BottomText>{subscriptionPickerTextFromSanity}</BottomText>
    </SubscriptionDescriptionWrapper>
  );
};

export default SubscriptionDescription;
