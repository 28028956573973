import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { LandingPageSanity } from '../typings/sanity';
import Hero from '../components/landing/Hero';
import { getLandingPage, defaultLandingPage } from '../services/sanityCalls';
import Features from '../components/landing/Features';
import WideSection from '../components/landing/WideSection';
import TrialSection from '../components/landing/TrialSection';
import StackedSections from '../components/landing/StackedSections';
import FAQ from '../components/landing/FAQ';
import AuthContext from '../contexts/AuthContext';

const PageWrapper = styled.div`
  /* Target Safari */
  _::-webkit-full-page-media,
  _:future,
  :root .safari_only {
    flex: 1 0 auto;
  }
`;

const LandingPage = () => {
  const [landingPageContent, setLandingPageContent] = useState<LandingPageSanity>(defaultLandingPage);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    getLandingPage()
      .then((data) => setLandingPageContent(data as LandingPageSanity))
      .catch((errorPage) => setLandingPageContent(errorPage));
  }, [user.language]);

  return (
    <PageWrapper>
      <main id="main">
        <Hero content={landingPageContent.heroSection} />
        <Features featureIntro={landingPageContent.featureIntro} featureSections={landingPageContent.featureSections} standoutFeature={landingPageContent.standoutFeature} />
        <StackedSections content={landingPageContent.stackedSections} />
        <TrialSection content={landingPageContent.trialSection} />
        <WideSection wideSectionContentSanity={landingPageContent.wideSection} />
        <FAQ content={landingPageContent.FAQSection} />
      </main>
    </PageWrapper>
  );
};

export default LandingPage;
