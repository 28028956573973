import styled from 'styled-components';
import { ButtonWrapper } from './InputLayouts';

export const ModalTopSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  h3 {
    font-weight: 700;
    margin: 0;
  }
`;
export const ClickableIconWrapper = styled.div`
  cursor: pointer;
  &:hover {
    opacity: 0.85;
  }
`;
export const ModalButtonWrapper = styled(ButtonWrapper)`
  width: 100%;
  margin-bottom: 0;
  justify-content: flex-end;
`;
