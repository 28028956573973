import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
require('es6-promise/auto');
export const client = sanityClient({
  projectId: '75hs4l5s',
  token: process.env.REACT_APP_SANITY_TOKEN,
  dataset: process.env.REACT_APP_SANITY_DATASET,
  useCdn: false, // `false` if you want to ensure fresh data
});

const builder = imageUrlBuilder();

// Then we like to make a simple function like this that gives the
// builder an image and returns the builder for you to specify additional
// parameters:
export const urlFor = (source: any) => {
  return builder.image(source);
};
