import styled, { keyframes } from 'styled-components';
import { COLORS } from '@laerdal/life-react-components';
import { NavLink } from 'react-router-dom';

export const flowDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Menu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: ${COLORS.white};
  box-sizing: border-box;
  display: none;
  right: -80px;
  top: -15px;
  min-width: 360px;
  -webkit-animation: ${flowDown} 0.2s ease-in-out;
  animation: ${flowDown} 0.2s ease-in-out;
  position: absolute;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  border: 1px solid ${COLORS.neutral_300};
  z-index: 100;

  .open & {
    display: block;
  }
`;

export const MenuSection = styled.li`
  position: relative;

  &:not(:first-of-type)::before {
    content: '';
    position: absolute;
    background: ${COLORS.neutral_100};
    height: 1px;
    top: 0;
    left: 16px;
    right: 16px;
  }

  .disabled-link {
    color: ${COLORS.neutral_800};
    pointer-events: none;
    key-events: none;
  }
`;

export const MenuSectionList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const MenuLink = styled(NavLink)`
  border: none;
  background: transparent;
  padding: 16px 48px;

  color: ${COLORS.black};
  text-decoration: none;
  text-align: left;
  align-items: center;
  display: flex;
  :hover {
    background-color: rgb(150, 150, 150, 0.1);
    cursor: pointer;
  }
`;
