import { COLORS, RadioButtonOff, RadioButtonOn } from '@laerdal/life-react-components';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

const RadioLabel = styled.label`
  margin-left: 10px;
  position: relative;
  top: -5px;
`;

const StyledRadioButton = styled.button`
  position: relative;
  left: -9px;
  top: 6px;
`;

const HiddenRadioButton = styled.input`
  position: absolute;
  left: -9999px;
  visibility: hidden;
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: baseline;
  height: 22px;

  svg {
    &:hover {
      cursor: pointer;
    }
  }

  button {
    background-color: ${COLORS.white};
    border-width: 0;
    margin: 0;
    width: 25px;
    height: 25px;
    padding: 0;
  }
`;

interface RadioButtonProps {
  name: string;
  value: number;
  selected: boolean;
  handleClick: (name: string, value?: number) => void;
}

const RadioButton = ({ name, value, selected, handleClick }: RadioButtonProps) => {
  return (
    <RadioWrapper>
      <RadioLabel onClick={(e) => handleClick(name, value)}>
        <StyledRadioButton type="button">
          {selected ? <RadioButtonOn size="25px" color={COLORS.primary_500} /> : <RadioButtonOff size="25px" color={COLORS.primary_500} />}
        </StyledRadioButton>
        <HiddenRadioButton type="radio" name="contactType" value={value} checked={selected} required readOnly />
        {name}
      </RadioLabel>
    </RadioWrapper>
  );
};

export default RadioButton;
