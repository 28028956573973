import React from 'react';
import styled from 'styled-components';
import { LandingStackedSection } from '../../typings/sanity';

const StackedSectionsWrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
  max-width: 1120px;
  justify-content: space-between;
  margin: 90px auto;
`;
const StackedSection = styled.article`
  width: 500px;
  @media (max-width: 1000px) {
    width: 90%;
    margin: 0 auto;
  }
`;
const StackedSectionImage = styled.img`
  width: 100%;
  object-fit: contain;
`;

const StackedSectionText = styled.div`
  & p {
    line-height: 30px;
  }
`;

interface StackedSectionProps {
  content: LandingStackedSection[];
}

const StackedSections = (props: StackedSectionProps) => {
  const stackedSections = props.content.map((item) => {
    return (
      <StackedSection key={item.title}>
        <StackedSectionImage src={item.image} alt="" />
        <StackedSectionText>
          <h3>{item.title}</h3>
          <p>{item.description}</p>
        </StackedSectionText>
      </StackedSection>
    );
  });
  return <StackedSectionsWrapper>{stackedSections}</StackedSectionsWrapper>;
};

export default StackedSections;
