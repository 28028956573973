import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import OrganizationContext from '../../contexts/OrganizationContext';
import { Button, User, Delete, COLORS, Plus, Close, ToastContext } from '@laerdal/life-react-components';
import modalStyles from '../../styles/modalStyles';
import Modal from 'react-modal';
import { StyledLabel, InputField, ButtonWrapper } from '../layouts/InputLayouts';
import { SubscriptionLevel, OrganizationMember, AppAccess } from '../../typings/types';
import { PostAppUser, DeleteAppUser, ActivateUser } from '../../services/api';
import toastOptions from '../../assets/ToastOptions';
import { Link } from 'react-router-dom';
import { ClickableIconWrapper, ModalButtonWrapper, ModalTopSection } from '../layouts/ModalLayout';
import { RedButton } from '../layouts/AdminLayouts';

const UserManagementWrapper = styled.div`
  max-width: 650px;
  margin-bottom: 20px;
  & h1 {
    font-size: 32px;
    margin-bottom: 50px;
  }
`;
interface RowButtonWrapperProps {
  maxSize: boolean;
}
const RowButtonWrapper = styled.div<RowButtonWrapperProps>`
  display: flex;
  flex-direction: row;

  & span {
    font-size: 19;
    margin-right: 10px;
    font-weight: 700;

    ${(props) =>
      props.maxSize
        ? `color: ${COLORS.neutral_500};`
        : `color: ${COLORS.primary_500};
          cursor: pointer;
          &:hover {
          opacity: 0.85;
        }`}
  }
`;
const AddInstructorsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  & p {
    color: ${COLORS.neutral_500};
    font-size: 18px;
  }
  & a {
    text-decoration: none;
  }
  & h3 {
    font-weight: 300;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
  }
`;

export const RequestExtraInstructorsWrapper = styled(AddInstructorsWrapper)`
  margin-top: 30px;
`;

const Member = styled.div`
  display: flex;
  max-width: 650px;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

const IconAndEmailWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AddInstructorText = styled.p`
  margin: 0 0 10px 0;
`;

const MembersWrapper = styled.div`
  min-height: 200px;
  & ${Member}:nth-child(even) {
    background-color: ${COLORS.neutral_20};
  }
`;

export const SmallButton = styled(Button)`
  height: 50px;
  display: flex;
  align-items: center;
`;

const MemberInfoWrapper = styled.div`
  margin-left: 20px;
`;

const MemberNameText = styled.p`
  color: ${COLORS.neutral_500};
  margin: -10px 0 0 0;
`;

const ThinSubHeading = styled.h3`
  font-weight: 300;
`;

const GreyText = styled.p`
  color: ${COLORS.neutral_500};
  margin-top: -10px;
`;

const ButtonPlusIcon = styled(Plus)`
  transform: translateY(6px);
  margin-left: 8px;
`;

Modal.setAppElement('#root');

const UserManagement = () => {
  const { t } = useTranslation('Admin');
  const [inviteModalIsOpen, setInviteModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [newMember, setNewMember] = useState<OrganizationMember>({ email: '', firstName: '', lastName: '' });
  const [memberCount, setMemberCount] = useState(0);
  const [membersToDelete, setMembersToDelete] = useState<OrganizationMember | null>();
  const { organization, setOrganization } = useContext(OrganizationContext);
  const { addToast } = useContext(ToastContext);
  const isTrial = organization.subscription.subscriptionLevel === SubscriptionLevel.Trial;

  const validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const uniqueEmail = () => {
    if (organization.members.find((member) => member.email == newMember.email) === undefined) {
      return true;
    }

    return false;
  };

  const validateModal = (): boolean => {
    if (!!newMember.firstName && !!newMember.lastName && validateEmail(newMember.email) && uniqueEmail()) {
      return true;
    }

    return false;
  };

  const reActivateUser = async (userId: string) => {
    if (memberCount < organization.subscription.subscriptionSize) {
      try {
        const activatedUser = await ActivateUser(userId);
        const organizationMembers = organization.members.map((member) => {
          if (member.id === activatedUser.id) {
            member.appAccess = AppAccess.Active;
          }
          return member;
        });

        setOrganization({ ...organization, members: organizationMembers });
      } catch (error) {
        console.log(error);
        addToast(t('Unable to re-activate, if this problem persist, please '), {
          ...toastOptions.error,
          action: toastOptions.contact,
        });
      }
    }
  };

  useEffect(() => {
    if (!inviteModalIsOpen) {
      setNewMember({ email: '', firstName: '', lastName: '' });
    }
  }, [inviteModalIsOpen]);

  useEffect(() => {
    setMemberCount(organization?.members?.filter((member) => member.appAccess === AppAccess.Active).length);
  }, [organization]);
  const addAppUser = () => {
    PostAppUser(newMember)
      .then((appUser) => {
        const newOrg = { ...organization };
        newOrg.members.unshift(appUser);
        setOrganization(newOrg);
        addToast(t('User successfully added. An invitation has been sent to') + ` ${appUser.email}`, toastOptions.success);
      })
      .catch((error) => {
        addToast(
          error.response.status === 403
            ? t('Failed: This user belongs to a different organization')
            : error.response.status === 402
            ? t('Subscription limit reached')
            : error.response.status === 409
            ? t('A user already exists with that email')
            : error.response.status === 503
            ? t('Unable to connect to server. Please try again in 15 minutes. If this problem persists please ')
            : t('Unable to add user, if this problem persists'),
          {
            ...toastOptions.error,
            action: error.response.status !== 403 && error.response.status !== 409 ? toastOptions.contact : [],
          },
        );
      });
  };

  const deleteAppUser = (id: string) => {
    DeleteAppUser(id)
      .then(() => {
        const newOrg = { ...organization };
        newOrg.members.forEach((member: OrganizationMember, index: number) => {
          if (member.id === id) {
            newOrg.members.splice(index, 1);
          }
        });
        setOrganization(newOrg);
        addToast(t('User successfully deleted. Access to TeamReporter has been revoked.'), toastOptions.success);
      })
      .catch(() => {
        addToast(t('Unable to delete user, if this problem persists'), {
          ...toastOptions.error,
          action: toastOptions.contact,
        });
      });
  };

  const deleteModal = () => {
    return (
      <Modal isOpen={deleteModalIsOpen} onRequestClose={() => setDeleteModalIsOpen(false)} style={modalStyles} contentLabel="Modal to delete user">
        <ModalTopSection>
          <h3>{t('Delete user')}</h3>
          <ClickableIconWrapper onClick={() => setDeleteModalIsOpen(false)}>
            <Close size={'32px'} />
          </ClickableIconWrapper>
        </ModalTopSection>
        <p>{t('Are you sure you want to delete this instructor?')}</p>
        <GreyText>{membersToDelete?.email}</GreyText>
        <ModalButtonWrapper>
          <SmallButton variant={'tertiary'} onClick={() => setDeleteModalIsOpen(false)}>
            {t('Cancel')}
          </SmallButton>
          <RedButton
            onClick={() => {
              setDeleteModalIsOpen(false);
              deleteAppUser(membersToDelete?.id!);
            }}>
            {t('Delete')}
          </RedButton>
        </ModalButtonWrapper>
      </Modal>
    );
  };
  // Generate the list of active members with respective deletion modals
  const membersToDisplay = organization.members.map((member) => {
    return (
      <Member key={member.email}>
        <IconAndEmailWrapper>
          <User size={'24px'} />
          <MemberInfoWrapper>
            <AddInstructorText>{member.email}</AddInstructorText>
            <MemberNameText>{member.firstName + ' ' + member.lastName}</MemberNameText>
          </MemberInfoWrapper>
        </IconAndEmailWrapper>
        <RowButtonWrapper maxSize={memberCount >= organization.subscription.subscriptionSize}>
          {member.appAccess === AppAccess.InActive && <span onClick={() => member.id && reActivateUser(member.id)}>{t('Re-activate')}</span>}
          <ClickableIconWrapper
            onClick={() => {
              setMembersToDelete(member);
              setDeleteModalIsOpen(true);
            }}>
            <Delete size={'24px'} />
          </ClickableIconWrapper>
        </RowButtonWrapper>
      </Member>
    );
  });

  const addModal = () => {
    return (
      <Modal isOpen={inviteModalIsOpen} onRequestClose={() => setInviteModalIsOpen(false)} style={modalStyles} contentLabel="Modal to invite user">
        <ModalTopSection>
          <h3>{t('Invite user')}</h3>
          <ClickableIconWrapper onClick={() => setInviteModalIsOpen(false)}>
            <Close size={'32px'} />
          </ClickableIconWrapper>
        </ModalTopSection>
        <StyledLabel>{t('First name')}</StyledLabel>
        <InputField value={newMember.firstName} onChange={(e) => setNewMember({ ...newMember, firstName: e.target.value })} placeholder={'Kari'} autoFocus />

        <StyledLabel>{t('Last name')}</StyledLabel>
        <InputField value={newMember.lastName} onChange={(e) => setNewMember({ ...newMember, lastName: e.target.value })} placeholder={'Nordmann'} />

        <StyledLabel>{t('Organizational email address')}</StyledLabel>
        <InputField
          value={newMember.email}
          onChange={(e) => setNewMember({ ...newMember, email: e.target.value })}
          onKeyUp={(event) => {
            if (event.key === 'Enter' && validateModal()) {
              addAppUser();
              setInviteModalIsOpen(false);
            }
          }}
          placeholder={'default@yourcompany.com'}
        />

        <ModalButtonWrapper>
          <SmallButton variant={'tertiary'} onClick={() => setInviteModalIsOpen(false)}>
            {t('Cancel')}
          </SmallButton>
          <SmallButton
            onClick={() => {
              addAppUser();
              setInviteModalIsOpen(false);
            }}
            disabled={!validateModal()}>
            {t('Invite')}
          </SmallButton>
        </ModalButtonWrapper>
      </Modal>
    );
  };

  return (
    <UserManagementWrapper>
      {/* Modal to add instructors */}
      {addModal()}
      {deleteModal()}
      <h1>{t('User Management')}</h1>
      {organization.subscription.subscriptionLevel === SubscriptionLevel.Trial && <ThinSubHeading>{t('You can add 1 instructor in your trial.')}</ThinSubHeading>}
      <AddInstructorsWrapper>
        <h3>
          {t('User accounts')} ({memberCount}/{organization.subscription.subscriptionSize})
        </h3>
        <SmallButton disabled={organization.subscription.subscriptionSize <= memberCount} size={'normal'} onClick={() => setInviteModalIsOpen(true)}>
          {t('Add instructor')} <ButtonPlusIcon size={'24px'} />
        </SmallButton>
      </AddInstructorsWrapper>
      <MembersWrapper>{membersToDisplay}</MembersWrapper>
      {!isTrial && organization.subscription.subscriptionSize <= memberCount && (
        <RequestExtraInstructorsWrapper>
          <p>{t(`You have invited all the instructors in your plan`)}</p>
          <Link to="/request-extra-instructors">
            <SmallButton variant="secondary">{t('Request extra instructors')}</SmallButton>
          </Link>
        </RequestExtraInstructorsWrapper>
      )}
    </UserManagementWrapper>
  );
};

export default UserManagement;
