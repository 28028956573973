import React from 'react';
import styled from 'styled-components';
import { Button } from '@laerdal/life-react-components';
import { useTranslation } from 'react-i18next';
import { Redirect, RouteComponentProps, useHistory } from 'react-router-dom';

const RightButton = styled(Button)`
  margin-top: 30px;
  align-self: flex-end;
`;

const OrderCompletePageWrapper = styled.div`
  width: 75%;
  max-width: 600px;
  margin: 50px auto 0 auto;
  padding: 0 5%;
  display: flex;
  flex-direction: column;
`;

const OrderCompletePage = ({ location: { state } }: RouteComponentProps<any>) => {
  const { t } = useTranslation('CreateOrganization');
  const history = useHistory();
  const typeOfOrder = state as string;
  if (!typeOfOrder) {
    return <Redirect to={'/'} />;
  }
  return (
    <OrderCompletePageWrapper>
      <h2>{t('Order complete')}</h2>
      {typeOfOrder === 'trial' && (
        <>
          <p> {t('Thanks for starting your 30 days free trial.')}</p>
          <p>{t('You now have full access to Laerdal TeamReporter and can start inviting your colleagues to join you.')}</p>
        </>
      )}
      {typeOfOrder === 'pro' && (
        <>
          <p>{t('Thanks for placing your order. You will shortly receive an invoice that covers the selected period.')}</p>
          <p>{t('You now have full access to Laerdal TeamReporter and can start inviting your colleagues to join you.')}</p>
        </>
      )}
      {typeOfOrder === 'extra-instructors' && (
        <>
          <p>{t('Thanks for ordering extra instructors accounts.')}</p>
          <p>{t('You can start inviting your new colleagues to join you')}</p>
        </>
      )}
      {typeOfOrder === 'renew-subscription' && (
        <>
          <p>{t('Thanks for placing your order. You will shortly receive an invoice that covers the selected period.')}</p>
          <p>{t('You now have full access to Laerdal TeamReporter and can start inviting your colleagues to join you.')}</p>
        </>
      )}
      <RightButton size={'big'} onClick={() => history.push('/admin')}>
        {t('Go to TeamReporter')}
      </RightButton>
    </OrderCompletePageWrapper>
  );
};

export default OrderCompletePage;
