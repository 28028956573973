import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { COLORS } from '@laerdal/life-react-components';

const BasketTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px 0 15px 0;
  color: white;
  padding: 0 24px;
  & p {
    margin: 0;
    width: 100%;
    color: ${COLORS.black};
    font-size: 14px;
  }
`;

const BasketText = () => {
  const { t } = useTranslation('CreateOrganization');
  return (
    <BasketTextWrapper>
      <p>{t('This is your yearly price for the requested extra instructors. Your next invoice will be prorated according to your current contract')}</p>
    </BasketTextWrapper>
  );
};

export default BasketText;
