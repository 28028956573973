import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { DropdownFilter } from '@laerdal/life-react-components';
import { Country, State } from '../../typings/types';
import OrganizationContext from '../../contexts/OrganizationContext';

const StyledLabel = styled.p`
  padding: 1px 0 0px;
  margin-top: 16px;
  margin-bottom: 0px;
  font-size: 18px;
  line-height: 140%;
`;

interface CountryFieldProps {
  country: Country;
  handleChange: (value: string, firstParameter: 'country' | 'state', secondParameter?: 'zipCode' | 'city' | 'addressLine') => void;
  disabled?: boolean;
  showStateField?: boolean;
  state?: State;
  stateDisabled?: boolean;
}

export const CountryAndStateFields = ({ country, handleChange, disabled, showStateField, state, stateDisabled }: CountryFieldProps) => {
  const { t } = useTranslation('CreateOrganization');
  const { countries } = useContext(OrganizationContext);
  return (
    <>
      <StyledLabel>{t('Country or region')}</StyledLabel>
      <DropdownFilter
        id="country"
        list={countries.map((country) => country.name)}
        placeholder={t('Select...')}
        onSelect={(value) => {
          handleChange(value, 'country');
        }}
        initalValue={country?.name}
        required
        disableSorting
        messageOnNoResults={t('No result')}
        disabled={disabled}
      />
      {showStateField && country.name && country.states && country.states.length > 0 && (
        <>
          <StyledLabel>{t('State')}</StyledLabel>
          <DropdownFilter
            id="state"
            list={country.states.map((state) => state.name)}
            placeholder={t('Select...')}
            onSelect={(value) => {
              handleChange(value, 'state');
            }}
            initalValue={state?.name}
            required
            disableSorting
            messageOnNoResults={t('No result')}
            disabled={stateDisabled}
          />
        </>
      )}
    </>
  );
};
