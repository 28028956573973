import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { COLORS, Plus, Minus } from '@laerdal/life-react-components';

const SubscriptionAdjustInstructorCountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0 30px 0;
  color: white;
  padding: 0 24px;
`;

const LeftText = styled.p`
  margin: 0;
  width: 50%;
  color: ${COLORS.primary_500};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100px;
`;

interface AdjustButtonProps {
  disabled?: boolean;
}

const AdjustButton = styled.a<AdjustButtonProps>`
  background-color: ${(props) => (props.disabled ? `${COLORS.neutral_300}` : `${COLORS.primary_500}`)};
  border-radius: 50%;
  height: 40px;
  width: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  &:hover {
    opacity: 0.85;
  }
`;

interface SubscriptionAdjustInstructorCountProps {
  instructorCount: number;
  setInstructorCount: (size: number) => void;
}

const SubscriptionAdjustInstructorCount = ({ instructorCount, setInstructorCount }: SubscriptionAdjustInstructorCountProps) => {
  const { t } = useTranslation('CreateOrganization');
  return (
    <SubscriptionAdjustInstructorCountWrapper>
      <LeftText>{t('Need extra instructors? Adjust quantity.')}</LeftText>
      <ButtonWrapper>
        <AdjustButton disabled={instructorCount <= 1} onClick={() => (instructorCount > 1 ? setInstructorCount(instructorCount - 1) : setInstructorCount(1))}>
          <Minus size="24px" />
        </AdjustButton>
        <AdjustButton onClick={() => setInstructorCount(instructorCount + 1)}>
          <Plus size="24px" />
        </AdjustButton>
      </ButtonWrapper>
    </SubscriptionAdjustInstructorCountWrapper>
  );
};

export default SubscriptionAdjustInstructorCount;
