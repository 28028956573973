import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import OrganizationDetailsContext from '../contexts/OrganizationDetailsContext';
import BillingDetailsSummary from '../components/createOrganization/BillingDetailsSummary';
import ConfirmOrderSummary from '../components/basket/ConfirmOrderSummary';
import BasketContext from '../contexts/BasketContext';
import { ButtonWrapper } from '../components/layouts/InputLayouts';
import { Button, ToastContext } from '@laerdal/life-react-components';
import { useHistory } from 'react-router-dom';
import StepCounter from '../components/createOrganization/StepCounter';
import TermsCheckbox from '../components/createOrganization/TermsCheckbox';
import { createOrder } from '../services/functions';
import * as api from '../services/api';
import OrganizationContext from '../contexts/OrganizationContext';
import toastOptions from '../assets/ToastOptions';
import { PageWrapper, LeftWrapper } from '../components/layouts/OrderFlowLayouts';

const ConfirmOrderPage = () => {
  const { editOrganization } = useContext(OrganizationDetailsContext);
  const { chosenSubscription } = useContext(BasketContext);
  const { addToast } = useContext(ToastContext);
  const history = useHistory();
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const { t } = useTranslation('CreateOrganization');
  const { setOrganization } = useContext(OrganizationContext);
  const confirmOrder = () => {
    api
      .PostOrder(createOrder(editOrganization, chosenSubscription))
      .then((org) => {
        setOrganization({ ...org, subscription: { ...org.subscription, organizationHasBeenUpdated: true } });
        history.push('/order-complete', 'pro');
      })
      .catch((error) => {
        if (error.response.status === 503) {
          api
            .GetOrganization()
            .then((org) => {
              if (!!org) {
                setOrganization(org);
              }
            })
            .catch((error) => {
              addToast(t('Server responded with') + ` (${error?.response?.status}), ` + t(' reload the page or'), {
                ...toastOptions.error,
                action: [
                  {
                    label: 'contact us',
                    handler: () => window.open('https://www.laerdal.com/no/support/customer-service/contact-us/', '_blank'),
                  },
                ],
              });
            });
        }
        addToast(t('Unable to process order') + ` (${error.response.status}), ` + t('please try again later or'), {
          ...toastOptions.error,
          action: toastOptions.contact,
        });
      });
  };
  useEffect(() => {
    !editOrganization.primaryContactPerson && history.push('create-organization');
  }, []);

  return (
    <PageWrapper>
      <LeftWrapper>
        <StepCounter currentStep={3} />
        <h1>{t('Confirm your order')}</h1>
        <p>{t('Confirm your order and get immediate access to the TeamReporter app. We will contact your billing representative to set up invoicing')}</p>
        <BillingDetailsSummary contactPerson={editOrganization.primaryContactPerson} billingEmail={editOrganization.email} />
        <TermsCheckbox agreeToTerms={agreeToTerms} setAgreeToTerms={setAgreeToTerms} />
        <ButtonWrapper>
          <Button variant="secondary" size={'big'} onClick={() => history.push('/create-organization')}>
            {t('Cancel')}
          </Button>
          <Button type="submit" variant="primary" size={'big'} disabled={agreeToTerms ? false : true} onClick={confirmOrder}>
            {t('Confirm')}
          </Button>
        </ButtonWrapper>
      </LeftWrapper>
      <ConfirmOrderSummary />
    </PageWrapper>
  );
};

export default ConfirmOrderPage;
