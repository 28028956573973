import React, { useContext } from 'react';
import styled from 'styled-components';
import SubscriptionPrice from './basketComponents/SubscriptionPrice';
import BasketContext from '../../contexts/BasketContext';
import SubscriptionDescription from './basketComponents/SubscriptionDescription';
import SubscriptionSubTotal from './basketComponents/SubscriptionSubTotal';
import { SubscriptionLevel } from '../../typings/types';
import { Card } from '../layouts/OrderFlowLayouts';
import useSubscriptionLengthString from '../../hooks/useSubscriptionLengthString';
import usePrice from '../../hooks/usePrice';

const TrialBasketWrapper = styled.div`
  margin-left: 100px;
  @media (max-width: 768px) {
    margin: 0;
  }
`;

const TrialBasket = () => {
  const { chosenSubscription, subscriptionPickerText } = useContext(BasketContext);
  const freeTrial = chosenSubscription.subscriptionLevel === SubscriptionLevel.Trial;
  const billingFrequency = useSubscriptionLengthString(chosenSubscription.subscriptionLength);
  const [price, subTotal, taxesIncluded] = usePrice(chosenSubscription.subscriptionSize);

  return (
    <TrialBasketWrapper>
      <Card>
        <SubscriptionPrice
          billingFrequency={billingFrequency}
          instructorCount={chosenSubscription.subscriptionSize}
          freeTrial={freeTrial}
          priceToDisplay={freeTrial ? price.freeTrial : price.oneMonth}
        />
        <SubscriptionDescription instructorCount={chosenSubscription.subscriptionSize} freeTrial={freeTrial} subscriptionPickerTextFromSanity={subscriptionPickerText} />
        {!freeTrial && <SubscriptionSubTotal annualPriceToDisplay={subTotal} taxesIncluded={taxesIncluded} />}
      </Card>
    </TrialBasketWrapper>
  );
};

export default TrialBasket;
