import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Route, Switch, useRouteMatch, Redirect, useHistory } from 'react-router-dom';
import { PageWidth, Columns, COLORS, Button } from '@laerdal/life-react-components';
import LaerdalLogo from '../assets/images/laerdal-logo.svg';
import Tabs from '../components/navigation/Tabs';
import TabLink from '../components/navigation/TabLink';
import UserManagement from '../components/admin/UserManagement';
import OrganizationInfo from '../components/admin/OrganizationInfo';
import SubscriptionInfo from '../components/admin/SubscriptionInfo';
import { useTranslation } from 'react-i18next';
import OrganizationContext from '../contexts/OrganizationContext';
import { SubscriptionLevel } from '../typings/types';
import Modal from 'react-modal';
import modalStyles from '../styles/modalStyles';
import { ButtonWrapper } from '../components/layouts/InputLayouts';
import BasketContext, { defaultBasket } from '../contexts/BasketContext';

const ModalButtonWrapper = styled(ButtonWrapper)`
  margin-bottom: 10px;
  justify-content: flex-end;
`;

const Sidebar = styled.aside`
  display: flex;
  flex-direction: column;
`;

const ImageWrapper = styled.section`
  padding: 32px 0 48px 0;
  border-right: 2px solid ${COLORS.neutral_100};
`;

const Borders = styled.div`
  height: 100%;
  border-right: 2px solid ${COLORS.neutral_100};
`;

const AdminPage = () => {
  const { t } = useTranslation('Admin');
  const match = useRouteMatch();
  const { organization } = useContext(OrganizationContext);
  const [showExpiredModal, setShowExpiredModal] = useState(false);
  const { setChosenSubscription } = useContext(BasketContext);
  const history = useHistory();

  const upgradeToPro = () => {
    setChosenSubscription(defaultBasket);
    history.push('/create-organization');
  };

  const renewSubscription = () => {
    history.push('/renew-subscription');
  };

  useEffect(() => {
    organization.subscription && organization.subscription.isExpired && setShowExpiredModal(true);
  }, [organization.subscription]);

  return (
    <PageWidth>
      <Modal isOpen={showExpiredModal} style={modalStyles}>
        <h3>{organization.subscription.subscriptionLevel === SubscriptionLevel.Trial ? t('Your free trial is over') : t('Your subscription has expired')}</h3>
        <p>
          {organization.subscription.subscriptionLevel === SubscriptionLevel.Trial
            ? t('Upgrade your account to a Pro subscription to keep using TeamReporter.')
            : t('Renew your subscription to keep using TeamReporter')}
        </p>
        <ModalButtonWrapper>
          <Button variant="secondary" onClick={() => history.push('/')}>
            {t('Home')}
          </Button>
          <Button variant="primary" onClick={organization.subscription.subscriptionLevel === SubscriptionLevel.Trial ? upgradeToPro : renewSubscription}>
            {organization.subscription.subscriptionLevel === SubscriptionLevel.Trial ? t('Upgrade') : t('Renew')}
          </Button>
        </ModalButtonWrapper>
      </Modal>
      <Columns style={{ height: 'calc(100vh - 65px)' }} columns="25% 8fr">
        <Sidebar>
          <ImageWrapper>
            <img src={LaerdalLogo} width="160px" alt="" />
          </ImageWrapper>
          <section>
            <Tabs direction="column">
              <TabLink to={`${match.url}/user-management`} direction="column">
                {t('User management')}
              </TabLink>
              <TabLink to={`${match.url}/organization-info`} direction="column">
                {t('My organization')}
              </TabLink>
              <TabLink to={`${match.url}/subscription-info`} direction="column" redDot={organization.subscription!.subscriptionLevel === SubscriptionLevel.Trial}>
                {t('Subscription')}
              </TabLink>
            </Tabs>
          </section>
          <Borders />
        </Sidebar>
        <Switch>
          <Redirect exact path="/admin" to={`${match.path}/user-management`} />
          <Route path={`${match.path}/user-management`} component={UserManagement} />
          <Route path={`${match.path}/subscription-info`} component={() => <SubscriptionInfo upgradeToPro={upgradeToPro} />} />
          <Route path={`${match.path}/organization-info`} component={OrganizationInfo} />
        </Switch>
      </Columns>
    </PageWidth>
  );
};

export default AdminPage;
