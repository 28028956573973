import React, { useContext } from 'react';
import BasketContext from '../../contexts/BasketContext';
import OrganizationContext from '../../contexts/OrganizationContext';
import usePrice from '../../hooks/usePrice';
import useSubscriptionLengthString from '../../hooks/useSubscriptionLengthString';
import { Card } from '../layouts/OrderFlowLayouts';
import BasketText from './basketComponents/BasketText';
import SubscriptionAdjustInstructorCount from './basketComponents/SubscriptionAdjustInstructorCount';
import SubscriptionPrice from './basketComponents/SubscriptionPrice';
import SubscriptionSubTotal from './basketComponents/SubscriptionSubTotal';

const RequestExtraInstructorsBasket = () => {
  const { numberOfExtraInstructors, setNumberOfExtraInstructors } = useContext(BasketContext);
  const { organization } = useContext(OrganizationContext);
  const billingFrequency = useSubscriptionLengthString(organization.subscription.subscriptionLength);
  const [price, subTotal, taxesIncluded] = usePrice(numberOfExtraInstructors);

  return (
    <Card width="363px">
      <SubscriptionPrice billingFrequency={billingFrequency} instructorCount={numberOfExtraInstructors} priceToDisplay={price.oneMonth} extraUsers />
      <SubscriptionAdjustInstructorCount instructorCount={numberOfExtraInstructors} setInstructorCount={setNumberOfExtraInstructors} />
      <BasketText />
      <SubscriptionSubTotal annualPriceToDisplay={subTotal} taxesIncluded={taxesIncluded} />
    </Card>
  );
};

export default RequestExtraInstructorsBasket;
