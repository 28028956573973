import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { globalStyles } from '../styles';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LandingPage from '../pages/LandingPage';
import SignIn from '../pages/SignIn';
import ErrorBoundary from './ErrorBoundary';
import PlansAndPricingPage from '../pages/PlansAndPricingPage';
import NavBar from './navigation/NavBar';
import CreateOrganizationPage from '../pages/CreateOrganizationPage';
import BillingDetailsPage from '../pages/BillingDetailsPage';
import TermsAndConditions from '../pages/TermsAndConditions';
import ConfirmOrderPage from '../pages/ConfirmOrderPage';
import CreateOrganizationTrialPage from '../pages/CreateOrganizationTrialPage';
import ConfirmOrderTrialPage from '../pages/ConfirmOrderTrialPage';
import OrderCompletePage from '../pages/OrderCompletePage';
import AdminPage from '../pages/AdminPage';
import NotFoundPage from '../pages/NotFoundPage';
import PrivateRoute from './PrivateRoute';
import LoadingScreen from './LoadingScreen';
import { ToastProvider } from '@laerdal/life-react-components';
import { AuthProvider } from '../contexts/AuthContext';
import { BasketProvider } from '../contexts/BasketContext';
import { OrganizationDetailsProvider } from '../contexts/OrganizationDetailsContext';
import { OrganizationProvider } from '../contexts/OrganizationContext';
import RequestExtraInstructors from '../pages/RequestExtraInstructors';
import ConfirmExtraInstructors from '../pages/ConfirmExtraInstructors';
import ContactUsPage from '../pages/ContactUsPage';
import RenewSubscription from '../pages/RenewSubscriptionPage';
import ConfirmRenewSubscription from '../pages/ConfirmRenewSubscription';

const GlobalStyle = createGlobalStyle`${globalStyles}`;

const App = () => {
  return (
    <>
      <link href="https://fonts.googleapis.com/css?family=Lato:300,400,700" rel="stylesheet" />
      <GlobalStyle />
      <ErrorBoundary>
        <Router>
          <ToastProvider>
            <AuthProvider>
              <OrganizationDetailsProvider>
                <OrganizationProvider>
                  <BasketProvider>
                    <NavBar />
                    <LoadingScreen />
                    <Switch>
                      <Route exact path="/" component={LandingPage} />
                      <Route exact path="/plans-and-pricing" component={PlansAndPricingPage} />
                      <Route exact path="/sign-in" component={SignIn} />
                      <Route exact path="/contact-us" component={ContactUsPage} />
                      <PrivateRoute notForProUsers exact path="/create-organization" component={CreateOrganizationPage} />
                      <PrivateRoute notForProUsers exact path="/billing-details" component={BillingDetailsPage} />
                      <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
                      <PrivateRoute notForProUsers exact path="/confirm-order" component={ConfirmOrderPage} />
                      <PrivateRoute notForProUsers notForTrialUsers exact path="/create-organization-trial" component={CreateOrganizationTrialPage} />
                      <PrivateRoute notForProUsers notForTrialUsers exact path="/confirm-order-trial" component={ConfirmOrderTrialPage} />
                      <Route exact path="/order-complete" component={OrderCompletePage} />
                      <PrivateRoute needsSubscription path="/admin" component={AdminPage} />
                      <PrivateRoute needsSubscription notForTrialUsers exact path="/request-extra-instructors" component={RequestExtraInstructors} />
                      <PrivateRoute needsSubscription notForTrialUsers exact path="/confirm-extra-instructors" component={ConfirmExtraInstructors} />
                      <PrivateRoute needsSubscription notForTrialUsers needsActiveSubscription exact path="/renew-subscription" component={RenewSubscription} />
                      <PrivateRoute needsSubscription notForTrialUsers needsActiveSubscription exact path="/confirm-renew-subscription" component={ConfirmRenewSubscription} />
                      <Route component={NotFoundPage} />
                    </Switch>
                  </BasketProvider>
                </OrganizationProvider>
              </OrganizationDetailsProvider>
            </AuthProvider>
          </ToastProvider>
        </Router>
      </ErrorBoundary>
    </>
  );
};

export default App;
