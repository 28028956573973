import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import EditSubscriptionStepOne from '../components/admin/EditSubscriptionStepOne';
import SubscriptionPicker from '../components/basket/SubscriptionPicker';
import { PageWrapper } from '../components/layouts/OrderFlowLayouts';
import BasketContext from '../contexts/BasketContext';
import OrganizationContext from '../contexts/OrganizationContext';

const RenewSubscription = () => {
  const { t } = useTranslation('CreateOrganization');
  const { organization } = useContext(OrganizationContext);
  const { setChosenSubscription, chosenSubscription } = useContext(BasketContext);
  const { requestPageOneComplete } = useContext(BasketContext);

  useEffect(() => {
    !requestPageOneComplete &&
      setChosenSubscription({
        ...chosenSubscription,
        subscriptionSize: organization.subscription.subscriptionSize,
        subscriptionLength: organization.subscription.subscriptionLength,
        autoRenew: organization.subscription.autoRenew,
      });
  }, [organization]);

  const EditSubscription = {
    header: t('Renew your subscription'),
    description: t('It looks like you’re renewing your subscription. Please enter the required details before proceeding. The system will calculate your costs automatically.'),
    linkUrl: '/confirm-renew-subscription',
    requestSubscriptionRenewal: true,
  };

  return (
    <PageWrapper>
      <EditSubscriptionStepOne {...EditSubscription} />
      <SubscriptionPicker onFormPage />
    </PageWrapper>
  );
};

export default RenewSubscription;
