import React, { useContext } from 'react';
import styled from 'styled-components';
import BasketContext from '../../contexts/BasketContext';
import SubscriptionPrice from './basketComponents/SubscriptionPrice';
import { useTranslation } from 'react-i18next';
import { COLORS } from '@laerdal/life-react-components';
import { SubscriptionLevel } from '../../typings/types';
import moment from 'moment';
import { Card } from '../layouts/OrderFlowLayouts';
import ConfirmOrderSubTotal from './basketComponents/ConfirmOrderSubTotal';
import useSubscriptionLengthString from '../../hooks/useSubscriptionLengthString';
import usePrice from '../../hooks/usePrice';

const ConfirmOrderSummaryWrapper = styled.div`
  margin-left: 100px;
  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const InvoiceInfo = styled.div`
  padding: 24px;
  margin-top: 24px;
  border-bottom: 1px solid ${COLORS.neutral_100};
  border-top: 1px solid ${COLORS.neutral_100};
  font-size: 16px;
`;

const ConfirmOrderSummary = () => {
  const { t } = useTranslation('CreateOrganization');
  const { chosenSubscription } = useContext(BasketContext);
  const freeTrial = chosenSubscription.subscriptionLevel === SubscriptionLevel.Trial;
  const expirationDate = moment().add(30, 'days').format('LL');
  const billingFrequency = useSubscriptionLengthString(chosenSubscription.subscriptionLength);
  const [price, subtotal, taxesIncluded] = usePrice(chosenSubscription.subscriptionSize);

  return (
    <ConfirmOrderSummaryWrapper>
      <Card>
        <SubscriptionPrice
          billingFrequency={billingFrequency}
          instructorCount={chosenSubscription.subscriptionSize}
          confirmOrderPage={true}
          priceToDisplay={freeTrial ? price.freeTrial : price.oneMonth}
          freeTrial={freeTrial}
        />
        <InvoiceInfo> {freeTrial ? t('You will not receive an invoice') : t('You will get an invoice from Laerdal Medical')} </InvoiceInfo>
        <ConfirmOrderSubTotal
          billingFrequency={billingFrequency}
          autoRenew={chosenSubscription.autoRenew}
          expirationDate={expirationDate}
          annualPriceToDisplay={subtotal}
          freeTrial={freeTrial}
          taxesIncluded={taxesIncluded}
        />
      </Card>
    </ConfirmOrderSummaryWrapper>
  );
};

export default ConfirmOrderSummary;
