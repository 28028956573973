import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { InvoiceInfo } from '../components/basket/ConfirmOrderSummary';
import SubscriptionPrice from '../components/basket/basketComponents/SubscriptionPrice';
import { Card, PageWrapper } from '../components/layouts/OrderFlowLayouts';
import BasketContext from '../contexts/BasketContext';
import OrganizationContext from '../contexts/OrganizationContext';
import useSubscriptionLengthString from '../hooks/useSubscriptionLengthString';
import usePrice from '../hooks/usePrice';
import ConfirmOrderSubTotal from '../components/basket/basketComponents/ConfirmOrderSubTotal';
import EditSubscriptionStepTwo from '../components/admin/EditSubscriptionStepTwo';
import { ToastContext } from '@laerdal/life-react-components';
import * as api from './../services/api';
import toastOptions from '../assets/ToastOptions';
import OrganizationDetailsContext from '../contexts/OrganizationDetailsContext';

const ConfirmRenewSubscription = () => {
  const { t } = useTranslation('CreateOrganization');
  const [] = useState(false);
  const { organization, setOrganization } = useContext(OrganizationContext);
  const { chosenSubscription, requestPageOneComplete, setRequestPageOneComplete } = useContext(BasketContext);
  const history = useHistory();
  const billingFrequency = useSubscriptionLengthString(chosenSubscription.subscriptionLength);
  const [price, subTotal, taxesIncluded] = usePrice(chosenSubscription.subscriptionSize);
  const { addToast } = useContext(ToastContext);

  const completeRenewSubscriptionOrder = async () => {
    try {
      const updatedOrganization = await api.RenewSubscription(chosenSubscription);
      setRequestPageOneComplete(false);
      setOrganization({ ...updatedOrganization, subscription: { ...updatedOrganization.subscription, organizationHasBeenUpdated: true } });
      history.push('/order-complete', 'renew-subscription');
    } catch {
      addToast(t('An error occurred while trying to renew subscription, please try again later or '), { ...toastOptions.error, action: toastOptions.contact });
    }
  };

  useEffect(() => {
    if (!requestPageOneComplete) {
      history.push('/renew-subscription');
    }
  }, []);

  const EditSubscription = {
    header: t('Confirm your order'),
    description: t('Confirm your order and get immediate access to the TeamReporter app.'),
    primaryContactPerson: organization.primaryContactPerson,
    billingEmail: organization.email,
    linkUrl: '/renew-subscription',
    requestSubscriptionRenewal: true,
    completeOrder: completeRenewSubscriptionOrder,
  };

  return (
    <PageWrapper>
      <EditSubscriptionStepTwo {...EditSubscription} />
      <Card width="463px">
        <SubscriptionPrice billingFrequency={billingFrequency} instructorCount={chosenSubscription.subscriptionSize} confirmOrderPage priceToDisplay={price.oneMonth} />
        <InvoiceInfo>{t('You will get an invoice from Laerdal Medical')}</InvoiceInfo>
        <ConfirmOrderSubTotal
          billingFrequency={billingFrequency}
          autoRenew={true}
          expirationDate={'expirationDate'}
          annualPriceToDisplay={subTotal}
          freeTrial={false}
          taxesIncluded={taxesIncluded}
        />
      </Card>
    </PageWrapper>
  );
};

export default ConfirmRenewSubscription;
