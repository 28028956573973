import React, { CSSProperties } from 'react';
import styled from 'styled-components';

type TabsProps = {
  direction?: string;
  children?: any;
};

const Nav = styled.nav`
  display: flex;

  @media print {
    display: none;
  }
`;

const Tabs: React.FunctionComponent<TabsProps> = ({ direction = 'row', children }) => (
  <Nav role="tablist" style={{ flexDirection: `${direction}` } as CSSProperties}>
    {children}
  </Nav>
);

export default Tabs;
