import { Button, Educator } from '@laerdal/life-react-components';
import React from 'react';
import styled from 'styled-components';
import { LandingFAQ } from '../../typings/sanity';

const FAQWrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;

const FAQContent = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  padding: 0 60px;
  display: flex;
  align-items: center;
  & button {
    margin-left: auto;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    & button {
      margin: 20px 0 0 0;
    }
  }
`;

const FAQText = styled.div`
  margin-left: 30px;
  & p {
    line-height: 30px;
    margin: 0;
  }
  & h2 {
    margin: 0 0 15px 0;
    font-size: 36px;
    font-weight: 300;
  }
`;

interface FAQProps {
  content: LandingFAQ;
}

const FAQ = ({ content: { title, description, button } }: FAQProps) => {
  return (
    <FAQWrapper>
      <FAQContent>
        <Educator size="46px" />
        <FAQText>
          <h2>{title}</h2>
          <p>{description}</p>
        </FAQText>
        <Button size="big" variant="secondary" onClick={() => window.open(button.buttonURL, '_blank')}>
          {button.buttonText}
        </Button>
      </FAQContent>
    </FAQWrapper>
  );
};

export default FAQ;
