import { useContext } from 'react';
import BasketContext from '../contexts/BasketContext';
import { SubscriptionLength } from '../typings/types';

const useSubscriptionLengthString = (subscriptionLength: SubscriptionLength) => {
  const { subscriptionOptions } = useContext(BasketContext);
  const matchedString = subscriptionOptions.find((option) => option.value === subscriptionLength)?.label.toLowerCase();
  if (matchedString != null) return ' ' + matchedString;
  return '';
};

export default useSubscriptionLengthString;
