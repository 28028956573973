import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '@laerdal/life-react-components';

const Spacer = styled.div`
  height: 10px;
`;

interface SubscriptionButtonsProps {
  purchaseNow: () => void;
  freeTrial: () => void;
  showTrialButton: boolean;
}

const SubscriptionButtons = (props: SubscriptionButtonsProps) => {
  const { t } = useTranslation('CreateOrganization');
  return (
    <>
      {props.showTrialButton && (
        <Button style={{ margin: '0 24px' }} onClick={props.freeTrial} size={'big'}>
          {t('Try free for 30 days')}
        </Button>
      )}
      <Spacer />
      <Button style={{ margin: '0 24px' }} onClick={props.purchaseNow} variant={'secondary'} size={'big'}>
        {t('Subscribe now')}
      </Button>
    </>
  );
};

export default SubscriptionButtons;
