import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { PriceValues, Subscription, SubscriptionLength } from '../../../typings/types';
import { SpacerWithBorder } from './SubscriptionPrice';
import { ChevronDown, COLORS, RadioButtonOn, RadioButtonOff, CheckboxOn, CheckboxOff, ChevronUp } from '@laerdal/life-react-components';
import { useTranslation } from 'react-i18next';
import { useSpring, animated } from 'react-spring';
import BasketContext from '../../../contexts/BasketContext';
import i18n from '../../../i18n';

const InvoicingOptionsWrapper = styled.div`
  padding: 0 24px;
  margin-bottom: 25px;
  & a {
    cursor: pointer;
    color: ${COLORS.primary_500};
    font-weight: 700;
    font-size: 16px;
  }
`;

const InvoicingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  width: 100%;
  & div {
    display: flex;
    align-items: center;
  }
  & svg {
    margin-right: 15px;
  }
  &:hover {
    background-color: rgb(150, 150, 150, 0.1);
    cursor: pointer;
  }
`;

const InvoicingRowNoHover = styled(InvoicingRow)`
  &:hover {
    background-color: initial;
    cursor: initial;
  }
  & svg {
    margin: initial;
  }
`;

const InvoicingSpacer = styled(SpacerWithBorder)`
  height: 20px;
`;

const Checkbox = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: ${COLORS.white};
  border-width: 0;
  margin: 0 10px 0 -7px;
`;

const CheckboxLine = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  p {
    margin: 0;
    font-size: 14px;
    color: ${COLORS.neutral_600};
  }
`;

type OptionsType = {
  label: string;
  value: SubscriptionLength;
  objectValue: string;
};

interface InvoicingOptionsProps {
  chosenSubscription: Subscription;
  setChosenSubscription: (chosenSubscription: Subscription) => void;
  price: PriceValues;
}

const InvoicingOptions = (props: InvoicingOptionsProps) => {
  const { t } = useTranslation('CreateOrganization');
  const { subscriptionOptions } = useContext(BasketContext);
  const [invoicingOptions, setInvoicingOptions] = useState(subscriptionOptions);
  const [showOptions, setShowOptions] = useState(false);
  const spring = useSpring({
    to: { height: showOptions ? '140px' : '45px' },
    from: { height: '45px' },
  });
  const [selectedOption, setSelectedOption] = useState(subscriptionOptions.find((option) => option.value === props.chosenSubscription.subscriptionLength) as OptionsType);
  const putSelectedOptionFirst = (option: OptionsType) => {
    const optionsWithSelectionFirst = invoicingOptions.filter((item) => item.objectValue !== option.objectValue);
    optionsWithSelectionFirst.unshift(option);
    setInvoicingOptions(optionsWithSelectionFirst);
  };

  const changeSelection = (option: OptionsType) => {
    setSelectedOption(option);
    putSelectedOptionFirst(option);
    setShowOptions(false);
  };

  const toggleOptions = () => {
    showOptions ? putSelectedOptionFirst(selectedOption) : setInvoicingOptions(subscriptionOptions);
    setShowOptions(!showOptions);
  };

  const radioRows = invoicingOptions.map((option) => (
    <InvoicingRow
      key={option.label}
      onClick={() => {
        changeSelection(option);
      }}>
      <div>
        {option.value === selectedOption?.value ? <RadioButtonOn color={COLORS.primary_500} size="24px" /> : <RadioButtonOff color={COLORS.primary_500} size="24px" />}{' '}
        <label>{option.label}</label>
      </div>
      <span>{props.price[option.objectValue as 'oneYear' | 'threeYears' | 'fiveYears']}</span>
    </InvoicingRow>
  ));

  useEffect(() => props.setChosenSubscription({ ...props.chosenSubscription, subscriptionLength: selectedOption.value }), [selectedOption]);
  useEffect(() => {
    if (selectedOption.value != props.chosenSubscription.subscriptionLength) {
      setSelectedOption(subscriptionOptions.find((option) => option.value === props.chosenSubscription.subscriptionLength) as OptionsType);
      putSelectedOptionFirst(subscriptionOptions.find((option) => option.value === props.chosenSubscription.subscriptionLength) as OptionsType);
    }
  }, [props.chosenSubscription.subscriptionLength]);
  useEffect(() => setInvoicingOptions(subscriptionOptions), [i18n.language]);
  useEffect(() => {
    setInvoicingOptions(subscriptionOptions);
    setSelectedOption(subscriptionOptions.find((option) => option.value === selectedOption.value) as OptionsType);
    putSelectedOptionFirst(subscriptionOptions.find((option) => option.value === selectedOption.value) as OptionsType);
  }, [subscriptionOptions]);

  return (
    <InvoicingOptionsWrapper>
      <InvoicingSpacer />
      <InvoicingRowNoHover>
        <span>
          <i>{t('Select an invoicing option')} </i>
        </span>
        <a onClick={toggleOptions}>
          {t('Options')}
          {showOptions ? <ChevronUp /> : <ChevronDown />}
        </a>
      </InvoicingRowNoHover>
      <animated.div style={{ ...spring, overflow: 'hidden' }}>{radioRows}</animated.div>

      <CheckboxLine>
        <Checkbox onClick={() => props.setChosenSubscription({ ...props.chosenSubscription, autoRenew: !props.chosenSubscription.autoRenew })}>
          {props.chosenSubscription.autoRenew ? <CheckboxOn size="26px" color={COLORS.primary_600} /> : <CheckboxOff size="26px" color={COLORS.primary_500} />}
        </Checkbox>
        <div>
          <label>{t('Auto-renew')}</label>
          <p>{t('You will receive a notification 2 months ahead of renewal')}.</p>
        </div>
      </CheckboxLine>
    </InvoicingOptionsWrapper>
  );
};

export default InvoicingOptions;
