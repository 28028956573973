import { Button, COLORS } from '@laerdal/life-react-components';
import styled from 'styled-components';
import { BREAKPOINTS } from '../../styles/index';
// Styled components generally belonging to the admin panel and its subpages

export const InfoWrapper = styled.div`
  & h1 {
    font-size: 32px;
    margin-bottom: 50px;
  }
  & h2 {
    font-size: 24px;
  }
  & h3 {
    margin: 10px 0 0 0;
    color: ${COLORS.neutral_500};
    font-size: 16px;
    font-weight: 400;
  }
  & p {
    margin: 0 0 6px 0;
  }
  margin-bottom: 30px;
`;

export const RedButton = styled(Button)`
  background-color: ${COLORS.critical_500};
  border: 1px solid ${COLORS.critical_500};
  :hover {
    background-color: ${COLORS.critical_700};
    border: 1px solid ${COLORS.critical_700};
  }
  :active {
    background-color: ${COLORS.critical_800};
    border: 1px solid ${COLORS.critical_800};
  }
  :focus {
    background-color: ${COLORS.critical_800};
    border: 1px solid ${COLORS.critical_800};
  }
`;

const GRID = {
  GAP: {
    SMALL: '24px',
    MEDIUM: '32px',
    LARGE: '40px',
  },
  GUTTER: {
    SMALL: '8px',
    MEDIUM: '32px',
    LARGE: '56px',
  },
};

export const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-gap: ${GRID.GAP.SMALL};
  grid-auto-rows: min-content;

  ${BREAKPOINTS.SMALL} {
    grid-template-columns: 1fr;
    -ms-grid-columns: 1fr;
    grid-gap: ${GRID.GAP.SMALL};
  }

  ${BREAKPOINTS.MEDIUM} {
    grid-template-columns: 1fr 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-gap: ${GRID.GAP.MEDIUM};
  }

  ${BREAKPOINTS.LARGE} {
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-gap: ${GRID.GAP.MEDIUM};
  }

  ${BREAKPOINTS.XLARGE} {
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-gap: ${GRID.GAP.LARGE};
  }

  /* Target IE11 */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: -ms-grid;

    & > * {
      display: block;
      margin-bottom: ${GRID.GAP.MEDIUM};

      &:nth-of-type(3n-2) {
        margin-left: 0;
        margin-right: calc(${GRID.GAP.MEDIUM} * 2 / 3);
      }

      &:nth-of-type(3n-1) {
        margin-left: calc(${GRID.GAP.MEDIUM} / 3);
        margin-right: calc(${GRID.GAP.MEDIUM} / 3);
      }

      &:nth-of-type(3n) {
        margin-right: 0;
        margin-left: calc(${GRID.GAP.MEDIUM} * 2 / 3);
      }
    }
  }
`;

export const RowLayout = styled.div`
  display: flex;
`;

export const Columns = styled.section<{ gap?: string; columns?: string; printColumns?: string }>`
  width: 100%;
  display: grid;
  grid-gap: ${(props) => props.gap || GRID.GAP.SMALL};
  word-break: break-word; /* Prevent long words from breaking the layout – consider making this rule global */

  ${BREAKPOINTS.MEDIUM} {
    grid-template-columns: ${(props) => props.columns};
    -ms-grid-columns: ${(props) => props.columns};
    grid-gap: ${(props) => props.gap || GRID.GAP.MEDIUM};
  }

  ${BREAKPOINTS.LARGE} {
    grid-gap: ${(props) => props.gap || GRID.GAP.LARGE};
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: -ms-grid;

    > *:not(:first-child) {
      margin-left: ${GRID.GAP.MEDIUM};
    }
  }

  @media print {
    grid-gap: 0;
    grid-template-columns: ${(props) => props.printColumns || 'none'};
  }
`;

export const PageWidth = styled.div`
  display: block;
  margin: 0 ${GRID.GUTTER.SMALL};

  ${BREAKPOINTS.MEDIUM} {
    margin: 0 ${GRID.GUTTER.MEDIUM};
  }

  ${BREAKPOINTS.LARGE} {
    margin: 0 ${GRID.GUTTER.LARGE};
  }

  ${BREAKPOINTS.XLARGE} {
    width: 1120px;
    margin: 0 auto;
  }
`;
