import { Order, Industry, Subscription, Country, TrialOrder, Organization, SubscriptionLevel, EditableOrganization, State } from '../typings/types';
import { ToastOptions } from '@laerdal/life-react-components/dist/js/Toasters/Toast';
import { AxiosError } from 'axios';
import toastOptions from '../assets/ToastOptions';
import { TFunction } from 'i18next';
import * as api from './api';
import { SetStateAction } from 'react';

export const createOrder = (editOrganization: EditableOrganization, subscription: Subscription): Order => {
  const order = {
    name: editOrganization.name,
    domain: editOrganization.domain.toLowerCase(),
    billingName: editOrganization.billingName,
    address: {
      addressLine: editOrganization.address.addressLine,
      zipCode: editOrganization.address.zipCode,
      country: {
        name: editOrganization.address.country.name,
        codeAlpha2: editOrganization.address.country.codeAlpha2,
        codeAlpha3: '',
      },
      state: editOrganization.address.state.code,
      city: editOrganization.address.city,
    },
    billingAddress: editOrganization.useSameAddress
      ? undefined
      : {
          addressLine: editOrganization.billingAddress.addressLine,
          zipCode: editOrganization.billingAddress.zipCode,
          country: {
            name: editOrganization.billingAddress.country.name,
            codeAlpha2: editOrganization.billingAddress.country.codeAlpha2,
            codeAlpha3: '',
          },
          state: editOrganization.billingAddress.state.code,
          city: editOrganization.billingAddress.city,
        },
    email: editOrganization.email,
    primaryContactPerson: editOrganization.primaryContactPerson,
    subscription: { ...subscription, expirationDate: undefined },
    industry: parseInt(editOrganization.industry.toString()),
    vatNumber: editOrganization.vatNumber,
    useSameAddress: editOrganization.useSameAddress,
  };
  return order;
};

export const createTrialOrder = (editOrganization: EditableOrganization, subscription: Subscription): TrialOrder => {
  const order = {
    name: editOrganization.name,
    domain: editOrganization.domain,
    address: {
      country: {
        name: editOrganization.address.country.name,
        codeAlpha2: editOrganization.address.country.codeAlpha2,
        codeAlpha3: '',
      },
      state: editOrganization.address.state.code,
      zipCode: editOrganization.address.zipCode,
    },
    subscription: { ...subscription, expirationDate: undefined },
    industry: parseInt(editOrganization.industry.toString()),
  };

  return order;
};

export const addErrorToast = (t: TFunction, addToast: (content: any, options: ToastOptions) => void, error: AxiosError) => {
  addToast(
    error.response?.status === 403
      ? t('You need to be an admin of your organization to perform this action')
      : t('Server responded with') + ` (${error.response?.status}), ` + t(' reload the page or'),
    {
      ...toastOptions.error,
      action: toastOptions.contact,
    },
  );
};

export const getSubscriptionStatus = async (organizationInContext: Organization): Promise<string | null> => {
  // Check if subscription is set in context
  if (!!organizationInContext.domain && organizationInContext.subscription?.subscriptionLevel === SubscriptionLevel.Trial) return Promise.resolve('trial');
  if (!!organizationInContext.domain && organizationInContext.subscription?.subscriptionLevel === SubscriptionLevel.Professional) return Promise.resolve('pro');
  if (!!organizationInContext?.address?.country?.codeAlpha2) return Promise.resolve('');

  return Promise.resolve(null);
};
export const createEditOrg = (org: Organization, countries: Country[]): EditableOrganization => {
  return {
    name: !!org.name ? org.name : '',
    domain: !!org.domain ? org.domain.slice(3) : '',
    billingName: !!org.billingName ? org.billingName : '',
    industry: org.industry != null && org.industry !== 5 ? org.industry : Industry.null,
    address: {
      addressLine: !!org.address && !!org.address.addressLine ? org.address.addressLine : '',
      zipCode: !!org.address && !!org.address.zipCode ? org.address.zipCode : '',
      country: {
        name: !!org.address && !!org.address.country && !!org.address.country.name ? org.address.country.name : '',
        codeAlpha2: !!org.address && !!org.address.country && !!org.address.country.name ? org.address.country.codeAlpha2 : '',
        codeAlpha3: !!org.address && !!org.address.country && !!org.address.country.name ? org.address.country.codeAlpha3 : '',
        states: !!org.address && !!org.address.country && org.address.country.codeAlpha2 ? getStates(org.address.country?.codeAlpha2, countries) : [{ name: '', code: '' }],
      },
      state:
        !!org.address && !!org.address.state ? (getStates(org.address.country?.codeAlpha2, countries).find((x) => x.code === org.address.state) as State) : { name: '', code: '' },
      city: !!org.address && !!org.address.city ? org.address.city : '',
    },
    billingAddress: {
      addressLine: !!org.billingAddress && !!org.billingAddress.addressLine ? org.billingAddress.addressLine : '',
      zipCode: !!org.billingAddress && !!org.billingAddress.zipCode ? org.billingAddress.zipCode : '',
      country: {
        name: !!org.billingAddress && !!org.billingAddress.country && !!org.billingAddress.country.name ? org.billingAddress.country.name : '',
        codeAlpha2: !!org.billingAddress && !!org.billingAddress.country && !!org.billingAddress.country.name ? org.billingAddress.country.codeAlpha2 : '',
        codeAlpha3: !!org.billingAddress && !!org.billingAddress.country && !!org.billingAddress.country.name ? org.billingAddress.country.codeAlpha3 : '',
        states:
          !!org.billingAddress && !!org.billingAddress.country && org.billingAddress.country.codeAlpha2
            ? getStates(org.billingAddress.country?.codeAlpha2, countries)
            : [{ name: '', code: '' }],
      },
      state:
        !!org.billingAddress && !!org.billingAddress.state
          ? (getStates(org.billingAddress.country?.codeAlpha2, countries).find((x) => x.code === org.billingAddress.state) as State)
          : { name: '', code: '' },
      city: !!org.billingAddress && !!org.billingAddress.city ? org.billingAddress.city : '',
    },
    email: !!org.email ? org.email : '',
    primaryContactPerson: !!org.primaryContactPerson ? org.primaryContactPerson : '',
    vatNumber: !!org.vatNumber ? org.vatNumber : '',
    useSameAddress: !!org.useSameAddress ? org.useSameAddress : false,
  };
};
export const getStates = (countryCode: string, countries: Country[]) => {
  const country = countries.find((country) => {
    return country.codeAlpha2 === countryCode;
  });
  if (country?.states) {
    return country.states;
  }
  return [];
};
