import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import SubscriptionPicker from '../components/basket/SubscriptionPicker';
import { PlansAndPricingContentFromSanity } from '../typings/sanity';
import BasketContext, { defaultBasket } from '../contexts/BasketContext';
import AuthContext from '../contexts/AuthContext';
import { getPlansAndPricingContentFromSanity } from '../services/sanityCalls';

const Header = styled.div`
  box-sizing: border-box;
  padding: 40px 0;
  text-align: center;

  & h1 {
    margin: 0;
    font-size: 48px;
    text-align: center;
  }

  & h2 {
    font-weight: 300;
    font-size: 32px;
  }
`;

const Main = styled.main`
  padding-bottom: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const ShowcaseImage = styled.img`
  object-fit: contain;
  margin: 40px 100px 0 0;
  max-width: 600px;
  min-width: 0;
  @media (max-width: 768px) {
    margin: 40px 0 0 0;
    width: 100%;
  }
`;

const PlansAndPricingPage = () => {
  const { setChosenSubscription } = useContext(BasketContext);
  const { user } = useContext(AuthContext);
  const [plansAndPricingContentFromSanity, setPlansAndPricingContentFromSanity] = useState<PlansAndPricingContentFromSanity>({
    header: '',
    subTitle: '',
    imageUrl: '',
  });

  useEffect(() => {
    setChosenSubscription(defaultBasket);
  }, []);

  useEffect(() => {
    getPlansAndPricingContentFromSanity()
      .then((data) => setPlansAndPricingContentFromSanity(data))
      .catch(() => console.log('Failed to load sanity information'));
  }, [user.language]);

  return (
    <>
      <Header>
        <h1>{plansAndPricingContentFromSanity.header}</h1>
        <h2>{plansAndPricingContentFromSanity.subTitle}</h2>
      </Header>
      <Main>
        <ShowcaseImage src={plansAndPricingContentFromSanity.imageUrl} alt="" />
        <SubscriptionPicker />
      </Main>
    </>
  );
};

export default PlansAndPricingPage;
