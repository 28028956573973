import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  'de-DE': {
    Admin: require('./i18n/de-DE/Admin.json'),
    CreateOrganization: require('./i18n/de-DE/CreateOrganization.json'),
    Landing: require('./i18n/de-DE/Landing.json'),
    Misc: require('./i18n/de-DE/Misc.json'),
    SignIn: require('./i18n/de-DE/SignIn.json'),
    ContactUs: require('./i18n/de-DE/ContactUs.json'),
    Navigation: require('./i18n/de-DE/Navigation.json'),
  },
  en: {
    Admin: require('./i18n/en/Admin.json'),
    CreateOrganization: require('./i18n/en/CreateOrganization.json'),
    Landing: require('./i18n/en/Landing.json'),
    Misc: require('./i18n/en/Misc.json'),
    SignIn: require('./i18n/en/SignIn.json'),
    ContactUs: require('./i18n/en/ContactUs.json'),
    Navigation: require('./i18n/en/Navigation.json'),
  },
  'es-ES': {
    Admin: require('./i18n/es-ES/Admin.json'),
    CreateOrganization: require('./i18n/es-ES/CreateOrganization.json'),
    Landing: require('./i18n/es-ES/Landing.json'),
    Misc: require('./i18n/es-ES/Misc.json'),
    SignIn: require('./i18n/es-ES/SignIn.json'),
    ContactUs: require('./i18n/es-ES/ContactUs.json'),
    Navigation: require('./i18n/es-ES/Navigation.json'),
  },
  'fr-FR': {
    Admin: require('./i18n/fr-FR/Admin.json'),
    CreateOrganization: require('./i18n/fr-FR/CreateOrganization.json'),
    Landing: require('./i18n/fr-FR/Landing.json'),
    Misc: require('./i18n/fr-FR/Misc.json'),
    SignIn: require('./i18n/fr-FR/SignIn.json'),
    ContactUs: require('./i18n/fr-FR/ContactUs.json'),
    Navigation: require('./i18n/fr-FR/Navigation.json'),
  },
  'it-IT': {
    Admin: require('./i18n/it-IT/Admin.json'),
    CreateOrganization: require('./i18n/it-IT/CreateOrganization.json'),
    Landing: require('./i18n/it-IT/Landing.json'),
    Misc: require('./i18n/it-IT/Misc.json'),
    SignIn: require('./i18n/it-IT/SignIn.json'),
    ContactUs: require('./i18n/it-IT/ContactUs.json'),
    Navigation: require('./i18n/it-IT/Navigation.json'),
  },
  'ja-JP': {
    Admin: require('./i18n/ja-JP/Admin.json'),
    CreateOrganization: require('./i18n/ja-JP/CreateOrganization.json'),
    Landing: require('./i18n/ja-JP/Landing.json'),
    Misc: require('./i18n/ja-JP/Misc.json'),
    SignIn: require('./i18n/ja-JP/SignIn.json'),
    ContactUs: require('./i18n/ja-JP/ContactUs.json'),
    Navigation: require('./i18n/ja-JP/Navigation.json'),
  },
  'ko-KR': {
    Admin: require('./i18n/ko-KR/Admin.json'),
    CreateOrganization: require('./i18n/ko-KR/CreateOrganization.json'),
    Landing: require('./i18n/ko-KR/Landing.json'),
    Misc: require('./i18n/ko-KR/Misc.json'),
    SignIn: require('./i18n/ko-KR/SignIn.json'),
    ContactUs: require('./i18n/ko-KR/ContactUs.json'),
    Navigation: require('./i18n/ko-KR/Navigation.json'),
  },
  'nl-NL': {
    Admin: require('./i18n/nl-NL/Admin.json'),
    CreateOrganization: require('./i18n/nl-NL/CreateOrganization.json'),
    Landing: require('./i18n/nl-NL/Landing.json'),
    Misc: require('./i18n/nl-NL/Misc.json'),
    SignIn: require('./i18n/nl-NL/SignIn.json'),
    ContactUs: require('./i18n/nl-NL/ContactUs.json'),
    Navigation: require('./i18n/nl-NL/Navigation.json'),
  },
  'pl-PL': {
    Admin: require('./i18n/pl-PL/Admin.json'),
    CreateOrganization: require('./i18n/pl-PL/CreateOrganization.json'),
    Landing: require('./i18n/pl-PL/Landing.json'),
    Misc: require('./i18n/pl-PL/Misc.json'),
    SignIn: require('./i18n/pl-PL/SignIn.json'),
    ContactUs: require('./i18n/pl-PL/ContactUs.json'),
    Navigation: require('./i18n/pl-PL/Navigation.json'),
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    whitelist: ['en', 'de-DE', 'es-ES', 'fr-FR', 'it-IT', 'ja-JP', 'ko-KR', 'nl-NL', 'pl-PL'],
    fallbackLng: 'en',
    saveMissing: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
