import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import OrganizationDetailsForm from '../components/createOrganization/OrganizationDetailsForm';
import StepCounter from '../components/createOrganization/StepCounter';
import TrialBasket from '../components/basket/TrialBasket';
import OrganizationDetailsContext from '../contexts/OrganizationDetailsContext';
import OrganizationContext from '../contexts/OrganizationContext';
import { LeftWrapper, PageWrapper } from '../components/layouts/OrderFlowLayouts';
import { createEditOrg } from '../services/functions';
import BasketContext, { defaultBasket } from '../contexts/BasketContext';
import { SubscriptionLevel } from '../typings/types';

const CreateOrganizationTrialPage = () => {
  const { t } = useTranslation('CreateOrganization');
  const { organization: org, countries } = useContext(OrganizationContext);
  const { setEditOrganization, editOrganization } = useContext(OrganizationDetailsContext);
  const { chosenSubscription, setChosenSubscription } = useContext(BasketContext);

  useEffect(() => {
    if (editOrganization.domain === '') {
      setEditOrganization(createEditOrg(org, countries));
      setChosenSubscription({ ...defaultBasket, subscriptionLevel: SubscriptionLevel.Trial });
    }
  }, [org]);

  return (
    <PageWrapper>
      <LeftWrapper>
        <StepCounter currentStep={1} freeTrial />
        <h1>{t('Create organization')}</h1>
        <p>{t('It looks like you’re setting up a new organization. Please enter the required details before proceeding. ')}</p>
        <OrganizationDetailsForm isTrial />
      </LeftWrapper>
      <TrialBasket />
    </PageWrapper>
  );
};

export default CreateOrganizationTrialPage;
