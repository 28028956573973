import React from 'react';
import styled from 'styled-components';
import { usePromiseTracker } from 'react-promise-tracker';
import { LoadingIndicator as Loading, GlobalLoadingPage } from '@laerdal/life-react-components';

const LoadingWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
`;

const LoadingScreen = () => {
  const { promiseInProgress } = usePromiseTracker();
  if (promiseInProgress && window.location.pathname !== '/' && window.location.pathname !== '/plans-and-pricing') {
    return (
      <LoadingWrapper>
        <GlobalLoadingPage />;
      </LoadingWrapper>
    );
  } else {
    return null;
  }
};

export default LoadingScreen;
