import React from 'react';
import { useTranslation } from 'react-i18next';
import BillingDetailsForm from '../components/createOrganization/BillingDetailsForm';
import StepCounter from '../components/createOrganization/StepCounter';
import SubscriptionPicker from '../components/basket/SubscriptionPicker';
import { LeftWrapper, PageWrapper } from '../components/layouts/OrderFlowLayouts';

const BillingDetailsPage = () => {
  const { t } = useTranslation('CreateOrganization');

  return (
    <PageWrapper>
      <LeftWrapper>
        <StepCounter currentStep={2} />
        <h1>{t('Enter billing details')}</h1>
        <p>{t('Complete your purchase by providing the billing details for your organization')}</p>
        <BillingDetailsForm />
      </LeftWrapper>
      <SubscriptionPicker onFormPage />
    </PageWrapper>
  );
};

export default BillingDetailsPage;
