import { Button } from '@laerdal/life-react-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import BillingDetailsSummary from '../createOrganization/BillingDetailsSummary';
import StepCounter from '../createOrganization/StepCounter';
import TermsCheckbox from '../createOrganization/TermsCheckbox';
import { ButtonWrapper } from '../layouts/InputLayouts';
import { LeftWrapper } from '../layouts/OrderFlowLayouts';
import { useHistory } from 'react-router';

interface EditSubscriptionStepTwoProps {
  header: string;
  description: string;
  primaryContactPerson: string;
  billingEmail: string;
  linkUrl: string;
  requestExtraInstructors?: boolean;
  requestSubscriptionRenewal?: boolean;
  completeOrder: () => void;
}

const EditSubscriptionStepTwo = ({
  header,
  description,
  primaryContactPerson,
  billingEmail,
  linkUrl,
  requestExtraInstructors,
  requestSubscriptionRenewal,
  completeOrder,
}: EditSubscriptionStepTwoProps) => {
  const { t } = useTranslation('Admin');
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const history = useHistory();

  return (
    <LeftWrapper>
      <StepCounter currentStep={2} requestSubscriptionRenewal={requestSubscriptionRenewal} requestExtraInstructors={requestExtraInstructors} />
      <h1>{header}</h1>
      <p>{description}</p>
      <BillingDetailsSummary contactPerson={primaryContactPerson} billingEmail={billingEmail} />
      <TermsCheckbox agreeToTerms={agreeToTerms} setAgreeToTerms={setAgreeToTerms} />
      <ButtonWrapper>
        <Button size="big" variant="secondary" onClick={() => history.push(linkUrl)}>
          {t('Cancel')}
        </Button>
        <Button disabled={!agreeToTerms} size="big" variant="primary" onClick={completeOrder}>
          {t('Confirm')}
        </Button>
      </ButtonWrapper>
    </LeftWrapper>
  );
};
export default EditSubscriptionStepTwo;
