// Styled components for the input fields, generally used in the create organization forms

import styled from 'styled-components';
import { COLORS } from '@laerdal/life-react-components';

export const CheckboxWrapper = styled.div`
  display: inline;
`;

export const Select = styled.select`
  appearance: none;
  border-radius: 4px;
  border: 1px solid ${COLORS.neutral_300};
  background: transparent;
  z-index: 10;
  padding: 8px 16px;
  font-size: 18px;
  width: 100%;
  height: 48px;
  color: ${COLORS.neutral_800};
  position: relative;

  &:disabled {
    background: ${COLORS.neutral_100};
    color: ${COLORS.neutral_800};
  }

  & option:disabled {
    font-style: italic;
    color: ${COLORS.neutral_400};
  }
  &:invalid {
    font-style: italic;
    color: ${COLORS.neutral_600};
  }
`;

export const IconPointer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  p {
    padding-left: 5px;
  }
`;

export const UnderText = styled.p`
  font-size: 14px;
  margin: 0.5em 0 0 2em;
  line-height: 120%;
`;

export const StyledLabel = styled.p`
  padding: 1px 0 0;
  margin-top: 16px;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 140%;
`;

export const InputField = styled.input`
  height: 56px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${COLORS.neutral_300};
  background-color: ${COLORS.white};
  box-sizing: border-box;
  padding: 0 16px;
  color: ${COLORS.black};
  outline: none;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05);
  font-size: 18px;

  &:disabled {
    border: 1px solid ${COLORS.neutral_100};
    pointer-events: none;
  }

  &:focus {
    box-shadow: inset 0px 0px 0px 2px ${COLORS.primary_800};
  }

  &:hover {
    box-shadow: inset 0px 0px 0px 2px ${COLORS.primary_700};
  }

  &::placeholder {
    font-style: italic;
    font-weight: normal;
    font-size: 18px;
    color: ${COLORS.neutral_400};
  }
`;

export const FormWrapper = styled.div``;

export const ButtonWrapper = styled.div`
  width: 260px;
  display: flex;
  justify-content: space-between;
  margin: 40px 0 40px auto;
  & :nth-child(2) {
    margin-left: 10px;
  }
`;

export const SingleButtonWrapper = styled.div`
  margin: 40px 0 0 auto;
  & a {
    text-decoration: none;
  }
`;

export const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  & svg {
    position: absolute;
    right: 12px;
    top: 20%;
    z-index: -1;
  }
`;

export const OptionalLine = styled.div`
  display: flex;
  position: relative;
`;

export const OptionalTag = styled.div`
  position: absolute;
  right: 0;
  font-size: 16px;
`;

export const Textarea = styled.textarea`
  width: 100%;
  height: 200px;
  box-sizing: border-box;
  padding: 16px;
  border: 1px solid ${COLORS.neutral_300};
  border-radius: 4px;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  ::placeholder {
    font-style: italic;
    color: ${COLORS.neutral_400};
  }
  &:hover {
    box-shadow: inset 0 0 0 2px ${COLORS.primary_700};
    border-color: transparent;
  }
  &:focus-within {
    border: 1px solid ${COLORS.primary_800};
  }
  &:focus {
    box-shadow: 0 0 8px ${COLORS.focus}, 0px 4px 12px rgba(0, 121, 189, 0.25);
    outline: none;
  }
`;
