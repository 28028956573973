import styled, { createGlobalStyle } from 'styled-components';
import { Link } from 'react-router-dom';
import { PageWidth } from './layouts/AdminLayouts';
import { COLORS } from '@laerdal/life-react-components';

export const GrayPageWidth = styled(PageWidth)`
  padding: 32px 0;
`;
export const GrayPageContent = styled.div`
  max-width: 564px;
`;

export const GrayPageGlobalStyle = createGlobalStyle`
body {
  background: ${COLORS.neutral_100};
}`;

export const GrayPageTitle = styled.h1`
  font-size: 48px;
  line-height: 140%;
  font-weight: 100;
  margin-bottom: 0;
`;
export const GrayPageBody = styled.div`
  margin-top: 40px;
  font-size: 18px;

  p {
    line-height: 140%;
  }
`;

export const GrayPageLink = styled(Link)`
  text-decoration: none;
  color: ${COLORS.primary_600};
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;
