import React, { useState } from 'react';
import { GrayPageGlobalStyle, GrayPageWidth, GrayPageContent, GrayPageTitle, GrayPageBody } from '../components/GrayPage';
import { Button } from '@laerdal/life-react-components';

const ErrorPage: React.SFC<{ error: Error | undefined }> = ({ error }) => {
  const [showDetails, setShowDetails] = useState(false);
  console.log(error);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <>
      <GrayPageGlobalStyle />
      <GrayPageWidth>
        <GrayPageContent>
          <GrayPageTitle>An error occured</GrayPageTitle>
          <GrayPageBody>
            <p>We are terribly sorry. Please try again or contact support if this problem persists.</p>
            {error && (
              <>
                <Button onClick={toggleDetails}>{showDetails ? 'Hide more information' : 'Show more information'}</Button>

                {showDetails && (
                  <>
                    <h2>{error?.message}</h2>
                    <pre>{error?.stack}</pre>
                  </>
                )}
              </>
            )}
          </GrayPageBody>
        </GrayPageContent>
      </GrayPageWidth>
    </>
  );
};

export default ErrorPage;
