import React from 'react';
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { COLORS } from '@laerdal/life-react-components';

const VerticalTabStyles = css`
  font-size: 16px;
  padding: 16px;
  border-top: none;
  border-left: 1px solid transparent;
  border-right: 2px solid ${COLORS.neutral_100};
  border-bottom: none;

  &.active {
    border-right: none;
    border-top: 1px solid ${COLORS.neutral_100};
    border-bottom: 1px solid ${COLORS.neutral_100};
    padding: 15px 16px;
  }

  &.active::after,
  &:hover::after {
    width: 3px;
    top: -1px;
    bottom: -1px;
    left: -1px;
  }
`;

const RedDot = styled.div`
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background-color: ${COLORS.critical_500};
`;

const HorizontalTabStyles = css`
  border-top: none;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: none;
  font-size: 18px;
  padding: 8px 16px;
  bottom: -1px;

  &.active {
    border-top: none;
    border-left: 1px solid ${COLORS.neutral_100};
    border-right: 1px solid ${COLORS.neutral_100};
    border-bottom: none;
  }

  &.active::after,
  &:hover::after {
    height: 3px;
    left: -1px;
    right: -1px;
    top: 0;
  }
`;

type StyledTabLinkProps = {
  direction: string;
  disabled?: boolean;
  exact: boolean;
};

const StyledTabLink = styled(NavLink)<StyledTabLinkProps>`
  ${(props) => (props.direction === 'row' ? HorizontalTabStyles : VerticalTabStyles)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => (props.disabled ? COLORS.neutral_400 : COLORS.black)};
  background-color: ${(props) => (props.disabled ? COLORS.neutral_100 : 'transparent')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  border-left: 1px solid transparent;
  text-decoration: none;
  position: relative;

  h1 {
    font-weight: normal;
  }

  &.active,
  &.active:hover,
  &.active:focus {
    cursor: default;
    background-color: ${COLORS.white};

    h1 {
      font-weight: bold;
    }
  }

  &:hover,
  &:focus {
    background-color: ${COLORS.neutral_100};

    &::after {
      position: absolute;
      content: '';
      background-color: ${COLORS.primary};
    }
  }

  &.active {
    &::after {
      position: absolute;
      content: '';
      background-color: ${(props) => (props.disabled ? COLORS.neutral_100 : COLORS.primary_600)};
    }
  }
`;

type TabLinkProps = {
  direction?: string;
  disabled?: boolean;
  redDot?: boolean;
} & NavLinkProps;

const TabLink = ({ to, children, direction = 'row', disabled = false, exact = false, redDot }: TabLinkProps) => {
  const location = useLocation();
  return (
    <StyledTabLink to={to as string} activeClassName="active" direction={direction} disabled={disabled} exact={exact} role="tab" aria-selected={to === location.pathname}>
      {children}
      {redDot && <RedDot />}
    </StyledTabLink>
  );
};

export default TabLink;
