import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { COLORS } from '@laerdal/life-react-components';

const SubTotalWrapper = styled.div`
  display: flex;
  margin: 0 0 -24px 0;
  background-color: ${COLORS.primary_100};
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
`;

const LeftText = styled.p``;
export const SubtotalText = styled.p`
  margin: 0;
  font-weight: 700;
`;
const SubText = styled.p`
  margin: 0;
  font-size: 14px;
  color: ${COLORS.neutral_700};
`;
const RightText = styled.div`
  height: fit-content;
  text-align: right;
`;

interface SubscriptionSubTotalProps {
  annualPriceToDisplay: string;
  taxesIncluded: boolean;
}

const SubscriptionSubTotal = (props: SubscriptionSubTotalProps) => {
  const { t } = useTranslation('CreateOrganization');
  return (
    <SubTotalWrapper>
      <LeftText>{t('Subtotal')}</LeftText>
      <RightText>
        <SubtotalText>{props.annualPriceToDisplay}</SubtotalText>
        <SubText>{props.taxesIncluded ? t('taxes included') : t('+ applicable taxes')}</SubText>
      </RightText>
    </SubTotalWrapper>
  );
};

export default SubscriptionSubTotal;
