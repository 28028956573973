const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '100vw',
    maxWidth: '600px',
    minWidth: '320px',
  },
};

export const modalWide = { ...modalStyles, content: { ...modalStyles.content, maxWidth: '1000px' } };

export default modalStyles;
