import { COLORS } from '@laerdal/life-react-components';
import styled from 'styled-components';
// This folder contains common styled components creating and upgrading your subscription

export const PageWrapper = styled.main`
  max-width: 1120px;
  padding: 0 50px 50px 50px;
  margin: 0 auto;
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    padding: 10px;
  }
`;

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-right: 50px;
  & h1 {
    font-weight: bold;
    font-size: 32px;
    line-height: 140%;
  }
`;
interface CardProps {
  width?: string;
}
export const Card = styled.article<CardProps>`
  margin-top: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  min-width: ${(props) => (props.width ? props.width : '350px')};
  max-width: 380px;
  background: white;
  text-decoration: none;
  color: ${COLORS.black};
  display: flex;
  flex-direction: column;
  border: 1px solid ${COLORS.neutral_100};
  padding: 24px 0;
  height: fit-content;
`;
