import React, { useState, useEffect, useContext } from 'react';
import SubscriptionPrice from './basketComponents/SubscriptionPrice';
import SubscriptionDescription from './basketComponents/SubscriptionDescription';
import SubscriptionAdjustInstructorCount from './basketComponents/SubscriptionAdjustInstructorCount';
import SubscriptionButtons from './basketComponents/SubscriptionButtons';
import BasketContext from '../../contexts/BasketContext';
import { SubscriptionLevel } from '../../typings/types';
import { useHistory } from 'react-router';
import AuthContext from '../../contexts/AuthContext';
import OrganizationContext from '../../contexts/OrganizationContext';
import InvoicingOptions from './basketComponents/InvoicingOptions';
import SubscriptionSubTotal from './basketComponents/SubscriptionSubTotal';
import { Card } from '../layouts/OrderFlowLayouts';
import useSubscriptionLengthString from '../../hooks/useSubscriptionLengthString';
import usePrice from '../../hooks/usePrice';

interface SubscriptionPickerProps {
  onFormPage?: boolean;
}

const SubscriptionPicker = (props: SubscriptionPickerProps) => {
  const { chosenSubscription, setChosenSubscription, subscriptionPickerText } = useContext(BasketContext);
  const { setIntent } = useContext(AuthContext);
  const { organization } = useContext(OrganizationContext);
  const { user } = useContext(AuthContext);
  const history = useHistory();
  const billingFrequency = useSubscriptionLengthString(chosenSubscription.subscriptionLength);
  const [price, subTotal, taxesIncluded] = usePrice(chosenSubscription.subscriptionSize);

  const purchaseNow = () => {
    setChosenSubscription({ ...chosenSubscription, subscriptionLevel: SubscriptionLevel.Professional });
    setIntent('/create-organization');
    history.push(user.isAuthenticated ? '/create-organization' : '/sign-in');
  };

  const freeTrial = () => {
    setChosenSubscription({ ...chosenSubscription, subscriptionLevel: SubscriptionLevel.Trial });
    setIntent('/create-organization-trial');
    history.push(user.isAuthenticated ? '/create-organization-trial' : '/sign-in');
  };

  const setSubscriptionSize = (size: number) => {
    setChosenSubscription({ ...chosenSubscription, subscriptionSize: size });
  };

  return (
    <Card>
      <SubscriptionPrice billingFrequency={billingFrequency} instructorCount={chosenSubscription.subscriptionSize} priceToDisplay={price.oneMonth} />
      <SubscriptionDescription instructorCount={chosenSubscription.subscriptionSize} subscriptionPickerTextFromSanity={subscriptionPickerText} />
      <SubscriptionAdjustInstructorCount instructorCount={chosenSubscription.subscriptionSize} setInstructorCount={setSubscriptionSize} />
      {props.onFormPage ? (
        <>
          <InvoicingOptions price={price} chosenSubscription={chosenSubscription} setChosenSubscription={setChosenSubscription} />
          <SubscriptionSubTotal annualPriceToDisplay={subTotal} taxesIncluded={taxesIncluded} />
        </>
      ) : (
        <SubscriptionButtons showTrialButton={!organization.domain} purchaseNow={purchaseNow} freeTrial={freeTrial} />
      )}
    </Card>
  );
};

export default SubscriptionPicker;
