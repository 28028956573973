import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import OrganizationalDetailsForm from '../components/createOrganization/OrganizationDetailsForm';
import StepCounter from '../components/createOrganization/StepCounter';
import SubscriptionPicker from '../components/basket/SubscriptionPicker';
import OrganizationContext from '../contexts/OrganizationContext';
import OrganizationDetailsContext from '../contexts/OrganizationDetailsContext';
import { LeftWrapper, PageWrapper } from '../components/layouts/OrderFlowLayouts';
import { createEditOrg } from '../services/functions';
import BasketContext, { defaultBasket } from '../contexts/BasketContext';

const CreateOrganizationPage = () => {
  const { t } = useTranslation('CreateOrganization');
  const { organization: org, countries } = useContext(OrganizationContext);
  const { setEditOrganization, editOrganization } = useContext(OrganizationDetailsContext);
  const { setChosenSubscription } = useContext(BasketContext);

  useEffect(() => {
    if (editOrganization.domain === '') {
      setEditOrganization(createEditOrg(org, countries));
      setChosenSubscription({ ...defaultBasket });
    }
  }, [org]);

  return (
    <PageWrapper>
      <LeftWrapper>
        <StepCounter currentStep={1} />
        <h1>{!!org.domain ? t('Upgrade your organization') : t('Create your organization')}</h1>
        <p>
          {!!org.domain
            ? t('It looks like you’re upgrading your subscription. Please enter the required details, and pick a number of instructors on the right hand side.')
            : t('It looks like you’re setting up a new organization. Please enter the required details before proceeding.')}
        </p>
        <OrganizationalDetailsForm />
      </LeftWrapper>
      <SubscriptionPicker onFormPage />
    </PageWrapper>
  );
};

export default CreateOrganizationPage;
