import React, { useState, useEffect, useContext } from 'react';
import { Subscription, SubscriptionLevel, SubscriptionLength, Price } from '../typings/types';
import { SubscriptionPickerContentFromSanity } from '../typings/sanity';
import AuthContext from './AuthContext';
import { getSubscriptionPickerContentFromSanity } from '../services/sanityCalls';
import { useTranslation } from 'react-i18next';

export const defaultBasket: Subscription = {
  subscriptionLevel: SubscriptionLevel.Professional,
  subscriptionSize: 1,
  autoRenew: true,
  subscriptionLength: SubscriptionLength.OneYear,
  isExpired: false,
};

export const defaultBasketTrial: Subscription = {
  subscriptionLevel: SubscriptionLevel.Trial,
  subscriptionSize: 1,
  autoRenew: true,
  subscriptionLength: SubscriptionLength.ThirtyDays,
  isExpired: false,
  expirationDate: '',
};

export const defaultPrice = { value: 0, currency: 'USD', languageCountryCode: 'en-us', ISOCode: 'us' };

type Props = {
  children: any;
};
interface SubcriptionOptions {
  label: string;
  value: SubscriptionLength;
  objectValue: string;
}
interface BasketProps {
  chosenSubscription: Subscription;
  subscriptionPickerText: string;
  setChosenSubscription: (subscription: Subscription) => void;
  numberOfExtraInstructors: number;
  requestPageOneComplete: boolean;
  setNumberOfExtraInstructors: React.Dispatch<React.SetStateAction<number>>;
  setRequestPageOneComplete: React.Dispatch<React.SetStateAction<boolean>>;
  prices: Price[];
  setPrices: React.Dispatch<React.SetStateAction<Price[]>>;
  subscriptionOptions: SubcriptionOptions[];
}

const BasketContext = React.createContext<BasketProps>({} as BasketProps);

export const BasketProvider: React.FunctionComponent<Props> = ({ children }: Props) => {
  const { user } = useContext(AuthContext);
  const [chosenSubscription, setChosenSubscription] = useState<Subscription>(defaultBasket);
  const [prices, setPrices] = useState<Price[]>([]);
  const { t } = useTranslation('CreateOrganization');
  const [subscriptionOptions, setSubscriptionOptions] = useState([
    { label: t('Annually'), value: SubscriptionLength.OneYear, objectValue: 'oneYear' },
    { label: t('Every 3 years'), value: SubscriptionLength.ThreeYears, objectValue: 'threeYears' },
    { label: t('Every 5 years'), value: SubscriptionLength.FiveYears, objectValue: 'fiveYears' },
  ]);
  const [numberOfExtraInstructors, setNumberOfExtraInstructors] = useState(1);
  const [requestPageOneComplete, setRequestPageOneComplete] = useState(false);
  const [subscriptionPickerContentFromSanity, setSubscriptionPickerContentFromSanity] = useState<SubscriptionPickerContentFromSanity>({
    monthlyPrice: 0,
    subscriptionPickerText: '',
  });

  useEffect(() => {
    setSubscriptionOptions([
      { label: t('Annually'), value: SubscriptionLength.OneYear, objectValue: 'oneYear' },
      { label: t('Every 3 years'), value: SubscriptionLength.ThreeYears, objectValue: 'threeYears' },
      { label: t('Every 5 years'), value: SubscriptionLength.FiveYears, objectValue: 'fiveYears' },
    ]);
    getSubscriptionPickerContentFromSanity()
      .then((data) => setSubscriptionPickerContentFromSanity(data))
      .catch(() => console.log('Failed to load sanity information'));
  }, [user.language]);

  return (
    <BasketContext.Provider
      value={{
        chosenSubscription,
        setChosenSubscription,
        numberOfExtraInstructors,
        setNumberOfExtraInstructors,
        subscriptionPickerText: subscriptionPickerContentFromSanity.subscriptionPickerText,
        prices,
        setPrices,
        requestPageOneComplete,
        setRequestPageOneComplete,
        subscriptionOptions,
      }}>
      {children}
    </BasketContext.Provider>
  );
};

export default BasketContext;
