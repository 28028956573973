import React from 'react';
import styled from 'styled-components';
import { COLORS, CheckComplete } from '@laerdal/life-react-components';
import { useHistory } from 'react-router-dom';

const StepCounterWrapper = styled.div`
  width: 300;
  margin: 40px 0;
  display: flex;
  align-items: center;
`;

interface StepProps {
  active?: boolean;
  finished?: boolean;
}
const Step = styled.div<StepProps>`
  background-color: ${(props) => (props.active && COLORS.neutral_700) || (props.finished && COLORS.primary_500) || COLORS.neutral_200};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.finished ? 'pointer' : 'auto')};
  p {
    color: ${(props) => (props.active ? COLORS.neutral_100 : COLORS.neutral_800)};
    font-weight: 700;
    font-size: 16px;
    margin: 0;
  }
  :hover {
    opacity: ${(props) => (props.finished ? '0.85' : '1')};
  }
`;

const StepBar = styled.div<StepProps>`
  background-color: ${(props) => (props.finished ? COLORS.primary_500 : COLORS.neutral_200)};
  width: 92px;
  height: 6px;
  margin: 5px;
  border-radius: 3px;
`;

interface StepRoutesInterface {
  1: string;
  2: string;
  3: string;
  [key: number]: string;
}

interface StepCounterProps {
  currentStep: number;
  freeTrial?: boolean;
  requestExtraInstructors?: boolean;
  requestSubscriptionRenewal?: boolean;
}

const FreeTrialRoutes = {
  1: '/create-organization-trial',
  2: '/confirm-order-trial',
  3: '/',
};

const requestExtraInstructorsRoutes = {
  1: '/request-extra-instructors',
  2: '/confirm-extra-instructors',
  3: '/',
};

const requestSubscriptionRenewalRoutes = {
  1: '/renew-subscription',
  2: '/confirm-renew-subscription',
  3: '/',
};

const StepCounter = ({ currentStep, freeTrial, requestExtraInstructors, requestSubscriptionRenewal }: StepCounterProps) => {
  const history = useHistory();

  let stepRoutes: StepRoutesInterface = {
    1: '/create-organization',
    2: '/billing-details',
    3: '/confirm-order',
  };

  if (freeTrial) stepRoutes = { ...FreeTrialRoutes };
  if (requestExtraInstructors) stepRoutes = { ...requestExtraInstructorsRoutes };
  if (requestSubscriptionRenewal) stepRoutes = { ...requestSubscriptionRenewalRoutes };

  const changeStep = (StepUserWantsToGoTo: number) => {
    //Ensures that the user can only go backwards in steps
    if (StepUserWantsToGoTo < currentStep) {
      history.push(stepRoutes[StepUserWantsToGoTo]);
    }
  };

  return (
    <StepCounterWrapper>
      <Step active={currentStep === 1} finished={currentStep > 1} onClick={() => changeStep(1)}>
        {currentStep > 1 ? <CheckComplete size={'24px'} color={COLORS.neutral_100} /> : <p>1</p>}
      </Step>
      <StepBar finished={currentStep > 1} />

      <Step active={currentStep === 2} finished={currentStep > 2} onClick={() => changeStep(2)}>
        {currentStep > 2 ? <CheckComplete size={'24px'} color={COLORS.neutral_100} /> : <p>2</p>}
      </Step>
      {!(freeTrial || requestExtraInstructors || requestSubscriptionRenewal) && (
        <>
          <StepBar finished={currentStep > 2} />
          <Step active={currentStep === 3} onClick={() => changeStep(3)}>
            {currentStep > 3 ? <CheckComplete size={'24px'} color={COLORS.neutral_100} /> : <p>3</p>}
          </Step>
        </>
      )}
    </StepCounterWrapper>
  );
};

export default StepCounter;
