import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import NotFoundImage from '../assets/images/404-man.svg';
import { Button } from '@laerdal/life-react-components';
import { useHistory } from 'react-router-dom';

const NotFoundWrapper = styled.div`
  max-width: 800px;
  margin: 50px auto;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const NotFoundMan = styled.img`
  width: 200px;
  margin-right: 50px;
`;

const ContentWrapper = styled.div`
  height: 350px;
  display: flex;
  flex-direction: column;
`;

const RightButton = styled(Button)`
  justify-self: flex-end;
  align-self: flex-end;
  margin-top: auto;
`;

const NotFoundPage = () => {
  const { t } = useTranslation('Misc');
  const history = useHistory();
  return (
    <NotFoundWrapper>
      <NotFoundMan src={NotFoundImage} alt="" />
      <ContentWrapper>
        <h1>{t('We lost this page (404)')}</h1>
        <p>{t(`We searched everywhere, but couldn't find what you're looking for. Let's find a better place for you to go.`)}</p>
        <RightButton size="big" onClick={() => history.push('/')}>
          {t('Go to TeamReporter Home')}
        </RightButton>
      </ContentWrapper>
    </NotFoundWrapper>
  );
};

export default NotFoundPage;
