import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { COLORS, DropdownFilter, ToastContext } from '@laerdal/life-react-components';
import { FormWrapper, StyledLabel, InputField, DropdownContainer, OptionalLine, OptionalTag, ButtonWrapper } from '../layouts/InputLayouts';
import OrganizationDetailsContext from '../../contexts/OrganizationDetailsContext';
import { CountryAndStateFields } from './CountryAndStateDropdown';
import { useHistory } from 'react-router';
import { Button, Attention } from '@laerdal/life-react-components';
import styled from 'styled-components';
import OrganizationContext from '../../contexts/OrganizationContext';
import { IsDomainInUse } from '../../services/api';
import { Country, Industry, State } from '../../typings/types';
import toastOptions from '../../assets/ToastOptions';
import { getEnabledCountries } from '../../services/sanityCalls';

const ButtonWrapperOrg = styled(ButtonWrapper)`
  justify-content: flex-end;
`;
interface ValidationTextProps {
  redText?: boolean;
}

const ValidationText = styled.div<ValidationTextProps>`
  margin-top: 4px;
  display: flex;
  align-items: center;
  & p {
    margin: 0 0 0 5px;
    font-size: 14px;
    color: ${(props) => props.redText && COLORS.critical_500};
  }
`;

type OrganizationalDetailsFormProps = {
  isTrial?: boolean;
};
type ValidSanityCountries = {
  countryValidation: string;
};

const OrganizationalDetailsForm = (props: OrganizationalDetailsFormProps) => {
  const [validationFailed, setValidationFailed] = useState(false);
  const { t } = useTranslation('CreateOrganization');
  const history = useHistory();
  const { organization, countries } = useContext(OrganizationContext);
  const { addToast } = useContext(ToastContext);
  const [enabledCountries, setEnabledCountries] = useState<string[]>([]);
  const { setEditOrganization, editOrganization } = useContext(OrganizationDetailsContext);

  const handleChange = (
    value: string,
    firstParameter: 'name' | 'domain' | 'industry' | 'address' | 'billingAddress' | 'vatNumber' | 'country' | 'state',
    secondParameter?: 'zipCode' | 'city' | 'addressLine',
  ) => {
    const editableOrganization = { ...editOrganization };
    if (firstParameter === 'address' || firstParameter === 'billingAddress') {
      editableOrganization[firstParameter][secondParameter as 'zipCode' | 'city' | 'addressLine'] = value;
    } else if (firstParameter === 'industry') {
      const industryValue = industries.find((industry) => industry.label === value)?.value as Industry;
      editableOrganization.industry = industryValue;
    } else if (firstParameter === 'country') {
      const selectedCountry = countries.find((country) => country.name === value) as Country;
      editableOrganization.address.country = selectedCountry;
    } else if (firstParameter === 'state') {
      const selectedState = editOrganization.address.country.states.find((state) => state.name === value) as State;
      editableOrganization.address.state = selectedState;
    } else {
      editableOrganization[firstParameter] = value;
    }

    setEditOrganization(editableOrganization);
  };
  const industries = [
    { label: t('Healthcare'), value: Industry.Healthcare },
    { label: t('Educational Provider'), value: Industry.EducationalProvider },
    { label: t('Emergency Medical Service'), value: Industry.EmergencyMedicalService },
    { label: t('Gov/Federal and Military'), value: Industry.GovFederalAndMilitary },
    { label: t('Other'), value: Industry.Other },
  ];
  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateCountry()) {
      if (organization.domain) {
        history.push('billing-details');
        window.scrollTo(0, 0);
      } else {
        IsDomainInUse(editOrganization.domain).then((isDomainInUse) => {
          if (!isDomainInUse) {
            props.isTrial ? history.push('confirm-order-trial') : history.push('billing-details');
          } else {
            setValidationFailed(true);
          }
        });
      }
    }
  };
  const validateCountry = () => {
    if (enabledCountries.length > 0 && !enabledCountries.includes(editOrganization.address.country.codeAlpha2)) {
      addToast(t('It seems that TeamReporter is not available in your country yet. Stay tuned or contact us for more information.'), {
        ...toastOptions.errorClose,
        action: [
          {
            label: 'contact us',
            handler: () => window.open('https://www.laerdal.com/no/support/customer-service/contact-us/', '_blank'),
          },
        ],
      });
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (enabledCountries.length === 0) {
      getEnabledCountries().then((resp: ValidSanityCountries) => {
        let enabledCountriesList = resp.countryValidation.split(', ');
        enabledCountriesList.push('');
        setEnabledCountries(enabledCountriesList);
      });
    }
  }, []);

  return (
    <FormWrapper>
      <form onSubmit={(e) => submit(e)}>
        <StyledLabel>{t('Organization name')}</StyledLabel>
        <InputField
          value={editOrganization.name}
          disabled={!!organization.name}
          onChange={(e) => handleChange(e.target.value, 'name')}
          placeholder={'Laerdal Medical Corporation'}
          required
        />
        <StyledLabel>{t('Organization domain (used for logging in)')}</StyledLabel>
        <InputField
          value={editOrganization.domain}
          onChange={(e) => handleChange(e.target.value.toLowerCase(), 'domain')}
          placeholder={t('e.g.') + 'laerdal'}
          pattern="^(?!-)[a-z0-9\-]{0,7}[a-z0-9]$"
          required
          disabled={!!organization.domain}
          maxLength={8}
        />
        <ValidationText redText={validationFailed}>
          <Attention />
          <p>{t('Domain name can only use letters, numbers and hyphens. Must be unique.')}</p>
        </ValidationText>

        <StyledLabel>{t('Industry')}</StyledLabel>
        <DropdownFilter
          id="industry"
          list={industries.map((industry) => industry.label)}
          placeholder={t('Select...')}
          onSelect={(value: string) => {
            handleChange(value, 'industry');
          }}
          initalValue={industries.find((x) => x.value === editOrganization.industry)?.label as string}
          required
          disableSorting
          messageOnNoResults={t('No result')}
          disabled={!(organization.industry == null || organization.industry === Industry.null)}
        />
        <CountryAndStateFields
          country={editOrganization.address.country}
          handleChange={handleChange}
          disabled={!!organization.address && !!organization.address.country && !!organization.address.country.name}
          stateDisabled={!!organization.address && !!organization.address.state}
          state={editOrganization.address.state}
          showStateField={true}
        />
        <StyledLabel>{t('Zip or postal code')}</StyledLabel>
        <InputField
          value={editOrganization.address.zipCode}
          disabled={!!organization.address?.zipCode}
          onChange={(e) => handleChange(e.target.value, 'address', 'zipCode')}
          placeholder={'4013'}
          required
        />

        {!props.isTrial && (
          <>
            <StyledLabel>{t('City')}</StyledLabel>
            <InputField
              value={editOrganization.address.city}
              onChange={(e) => handleChange(e.target.value, 'address', 'city')}
              disabled={!!organization.address?.city}
              placeholder={'Stavanger'}
              required
            />

            <StyledLabel>{t('Address')}</StyledLabel>
            <InputField
              value={editOrganization.address.addressLine}
              onChange={(e) => handleChange(e.target.value, 'address', 'addressLine')}
              disabled={!!organization.address?.addressLine}
              placeholder={'Haakon street 32'}
              required
            />

            <OptionalLine>
              <StyledLabel>{t('VAT number')}</StyledLabel>
              <OptionalTag>
                <p>{t('Optional')}</p>
              </OptionalTag>
            </OptionalLine>
            <InputField
              value={editOrganization.vatNumber}
              disabled={!!organization.vatNumber}
              onChange={(e) => handleChange(e.target.value, 'vatNumber')}
              placeholder={'02931028493010'}
            />
          </>
        )}
        <ButtonWrapperOrg>
          <Button type="submit" variant="primary" size={'big'}>
            {t('Continue')}
          </Button>
        </ButtonWrapperOrg>
      </form>
    </FormWrapper>
  );
};

export default OrganizationalDetailsForm;
