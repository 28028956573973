import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, CheckboxOff, CheckboxOn, COLORS, DropdownFilter, ToastContext } from '@laerdal/life-react-components';
import OrganizationContext from '../contexts/OrganizationContext';
import { GetCountryList } from '../services/api';
import { ContactType, ContactUsForm, Country } from '../typings/types';
import { PageWrapper } from '../components/layouts/OrderFlowLayouts';
import { FormWrapper, StyledLabel, InputField, CheckboxWrapper, IconPointer, ButtonWrapper, Textarea } from '../components/layouts/InputLayouts';
import RadioButton from '../components/RadioButton';
import AuthContext from '../contexts/AuthContext';
import * as api from '../services/api';
import { useHistory } from 'react-router';
import toastOptions from '../assets/ToastOptions';
import { addErrorToast } from '../services/functions';

const ContactUsWrapper = styled.div`
  max-width: 565px;
  margin: 16px auto;
  display: flex;
  flex-direction: column;
`;
const SubmitWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 26px;
`;

const ContactUsHeader = styled.div`
  h4 {
    font-weight: 700;
    margin: 8px 0;
    font-size: 16px;
    color: ${COLORS.accent1_700};
  }

  h1 {
    margin: 8px 0;
  }

  p {
    margin: 16px 0;
  }
`;

const ContactUsPage = () => {
  const { user } = useContext(AuthContext);
  const { organization } = useContext(OrganizationContext);
  const { countries, setCountries } = useContext(OrganizationContext);
  const { t } = useTranslation('ContactUs');
  const { addToast } = useContext(ToastContext);
  const history = useHistory();
  const [contactUsForm, setContactUsForm] = useState<ContactUsForm>({
    country: user.isAuthenticated && organization?.address?.country ? organization.address.country : { name: '', codeAlpha2: '', codeAlpha3: '' },
    email: user.isAuthenticated ? user.email : '',
    messageType: ContactType.GeneralComment,
    instructorCount: '',
    manikins: '',
    wantPredictiveMaintenance: false,
    message: '',
  });

  useEffect(() => {
    if (countries.length == 1) {
      GetCountryList().then((list: Country[]) => {
        setCountries(list);
      });
    }
  }, []);

  useEffect(() => {
    handleChange(user.email, 'email');
  }, [user.email]);

  useEffect(() => {
    if (!!organization.address.country.name) {
      handleChange(organization.address.country.name, 'country');
    }
  }, [organization.address.country]);

  const instructorsDropdown = [t('More than 5'), t('More than 10'), t('More than 20')];
  const manikinsDropdown = [t("Yes, I'm good"), t('Yes, but I need more'), t("No, I'd like to buy some"), t('No, Id like to lease some')];

  const messageTypeRadioButtons = [
    { name: t('General comment'), valueType: ContactType.GeneralComment },
    { name: t('Feedback'), valueType: ContactType.Feedback },
    { name: t('Report a problem'), valueType: ContactType.ReportProblem },
    { name: t("I'm interested in an Enterprise account"), valueType: ContactType.Enterprise },
  ];

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await api.PostContactUsForm(contactUsForm);
      history.push('/');
      addToast(t('Your message has been sent successfully. We will get back to you soon.'), toastOptions.success);
    } catch (error) {
      console.log(error);
      addToast(t('Could not submit form. Please make sure all fields are filled in.'), toastOptions.error);
    }
  };

  const handleChange = (value: string, name: 'email' | 'instructorCount' | 'manikins' | 'message' | 'country') => {
    const editContactUsForm = { ...contactUsForm };
    if (name === 'country') {
      const selectedCountry = countries.find((country) => country.name === value) as Country;
      editContactUsForm.country = { name: selectedCountry.name, codeAlpha2: selectedCountry.codeAlpha2, codeAlpha3: '' };
    } else {
      editContactUsForm[name] = value;
    }
    setContactUsForm(editContactUsForm);
  };

  const handleClick = (name: string, value?: number) => {
    const editContactUsForm = { ...contactUsForm };
    if (name === 'wantPredictiveMaintenance') {
      editContactUsForm.wantPredictiveMaintenance = !editContactUsForm.wantPredictiveMaintenance;
    } else if (value !== undefined) {
      editContactUsForm.messageType = value;
    }
    setContactUsForm(editContactUsForm);
  };

  const getCountryDropdown = () => (
    <>
      <StyledLabel>{t('Country or Region')}</StyledLabel>
      <DropdownFilter
        id="country"
        list={countries.map((country) => country.name)}
        placeholder={t('Select...')}
        onSelect={(value) => {
          handleChange(value, 'country');
        }}
        initalValue={contactUsForm.country.name}
        required
        disableSorting
        messageOnNoResults={t('No result')}
      />
    </>
  );

  const getEmailInputField = () => (
    <>
      <StyledLabel>{t('Email Address')}</StyledLabel>
      <InputField
        value={contactUsForm.email}
        onChange={(e) => {
          e.preventDefault();
          handleChange(e.target.value, 'email');
        }}
        placeholder={t('e.g.') + 'annie@laerdal.com'}
        name="email"
        type="email"
        required
      />
    </>
  );

  const getEnterpriseQuestions = () => (
    <>
      <StyledLabel>{t('How many instructor accounts do you need?')}</StyledLabel>
      <DropdownFilter
        id="instructor"
        list={instructorsDropdown}
        placeholder={t('Select...')}
        onSelect={(value) => {
          handleChange(value, 'instructorCount');
        }}
        required
        disableSorting
        messageOnNoResults={t('No result')}
      />
      <StyledLabel>{t('Do you already have Laerdal manikins?')}</StyledLabel>
      <DropdownFilter
        id="manikin"
        list={manikinsDropdown}
        placeholder={t('Select...')}
        onSelect={(value) => {
          handleChange(value, 'manikins');
        }}
        required
        disableSorting
        messageOnNoResults={t('No result')}
      />
      <CheckboxWrapper>
        <IconPointer
          onClick={() => handleClick('wantPredictiveMaintenance')}
          onKeyUp={(e: React.KeyboardEvent) => {
            if (e.key === ' ' || e.key === 'Spacebar' || e.key === 'Enter') {
              handleClick('wantPredictiveMaintenance');
            }
          }}
          tabIndex={0}>
          {contactUsForm.wantPredictiveMaintenance ? <CheckboxOn size="26px" color={COLORS.primary_500} /> : <CheckboxOff size="26px" color={COLORS.neutral_400} />}
          <p>{t("I'm interested in predictive maintenance")}</p>
        </IconPointer>
      </CheckboxWrapper>
    </>
  );

  const getMessageTypeRadioButtons = () => (
    <>
      {messageTypeRadioButtons.map((messageTypeRadioButton, index) => (
        <RadioButton
          name={messageTypeRadioButton.name}
          value={messageTypeRadioButton.valueType}
          selected={contactUsForm.messageType === messageTypeRadioButton.valueType}
          handleClick={handleClick}
          key={index}
        />
      ))}
    </>
  );

  return (
    <PageWrapper>
      <ContactUsWrapper>
        <ContactUsHeader>
          <h4>{t('TeamReporter')}</h4>
          <h1>{t('Contact us')}</h1>
          <p>{t('Complete this form to get in touch with our customer support team.')}</p>
        </ContactUsHeader>
        <FormWrapper>
          <form
            onSubmit={(e) => {
              submit(e);
            }}>
            {(!user.isAuthenticated || organization?.address?.country?.name === '') && getCountryDropdown()}
            {!user.isAuthenticated && getEmailInputField()}
            <StyledLabel>{t('What type of message do you want to send?')}</StyledLabel>
            {getMessageTypeRadioButtons()}
            {contactUsForm.messageType === ContactType.Enterprise && getEnterpriseQuestions()}
            <StyledLabel>
              {contactUsForm.messageType !== ContactType.Enterprise ? t('What would you like to tell us?') : t('Anything else we should know to offer the best deal possible?')}
            </StyledLabel>
            <Textarea
              placeholder={t('Enter a message using 3000 characters or less...')}
              value={contactUsForm.message}
              onChange={(e) => handleChange(e.target.value, 'message')}
              name="message"
              maxLength={3000}
              required></Textarea>
            <SubmitWrapper>
              <Button type="submit" variant="primary" size={'big'}>
                {t('Submit')}
              </Button>
            </SubmitWrapper>
          </form>
        </FormWrapper>
      </ContactUsWrapper>
    </PageWrapper>
  );
};

export default ContactUsPage;
