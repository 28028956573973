import React, { useEffect, useContext, useState, SetStateAction } from 'react';
import OrganizationContext from './OrganizationContext';
import { User, AccountInfo, LogoutResponse } from '../typings/types';
import { clearToken } from '../axios';
import { trackPromise } from 'react-promise-tracker';
import i18n from '../i18n';

const defaultUser = { isAuthenticated: false, id: '', firstName: '', lastName: '', email: '', language: i18n.language, userFetchComplete: false };

type Props = {
  children?: any;
};

interface AuthProps {
  intent: string;
  isLoggedIn: () => Promise<unknown>;
  setIntent: (intent: string) => void;
  logout: () => void;
  user: User;
  setUser: React.Dispatch<SetStateAction<User>>;
}

const AuthContext = React.createContext<AuthProps>({} as AuthProps);

export const AuthProvider: React.FC = ({ children }: Props) => {
  const { clearOrganization } = useContext(OrganizationContext);
  const [intent, setIntent] = useState('/');
  const [user, setUser] = useState<User>(defaultUser);

  const isLoggedIn = (): Promise<string> => {
    if (user.isAuthenticated) return Promise.resolve('Already logged in');
    return trackPromise(
      new Promise((resolve, reject) => {
        // @ts-ignore
        window.gigya?.accounts.getAccountInfo({
          callback: (e: AccountInfo) => {
            //Checks if user is logged in.
            if (e.errorCode === 0) {
              resolve(e.statusReason);
            } else {
              reject(e.statusReason);
            }
          },
        });
      }),
    );
  };

  const getUserFromGigya = () => {
    return trackPromise(
      new Promise<void>((resolve, reject) => {
        //@ts-ignore
        window.gigya.accounts.getAccountInfo({
          callback: (e: AccountInfo) => {
            //Checks if user is logged in.
            if (e.errorCode === 0) {
              setUser({
                isAuthenticated: true,
                id: e.UID,
                firstName: e.profile.firstName,
                lastName: e.profile.lastName,
                email: e.profile.email,
                language: i18n.language,
                userFetchComplete: true,
              });
            } else {
              setUser({ ...user, userFetchComplete: true });
            }
          },
        });
        resolve();
      }),
    );
  };

  const addLoginListener = () => {
    //@ts-ignore
    window.gigya.socialize.addEventHandlers({
      onLogin: async () => {
        getUserFromGigya();
      },
    });
  };

  const requestLogout = (): Promise<number> =>
    trackPromise(
      new Promise((resolve, reject) => {
        // @ts-ignore
        window.gigya?.accounts.logout({
          callback: (e: LogoutResponse) => {
            e.errorCode === 0 ? resolve(e.statusCode) : reject(e.errorCode);
          },
        });
      }),
    );

  const logout = () => {
    requestLogout()
      .then(() => {
        setUser(defaultUser);
        clearOrganization();
        clearToken();
        window.location.pathname = '/';
      })
      .catch(() => alert('Could not logout'));
  };

  useEffect(() => {
    getUserFromGigya();
  }, []);
  useEffect(addLoginListener, []);

  return <AuthContext.Provider value={{ user, setUser, intent, setIntent, logout, isLoggedIn }}>{children}</AuthContext.Provider>;
};

export default AuthContext;
