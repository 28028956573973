import { ToastContext } from '@laerdal/life-react-components';
import { AxiosError } from 'axios';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import toastOptions from '../assets/ToastOptions';
import { InvoiceInfo } from '../components/basket/ConfirmOrderSummary';
import SubscriptionPrice from '../components/basket/basketComponents/SubscriptionPrice';
import { Card, PageWrapper } from '../components/layouts/OrderFlowLayouts';
import BasketContext, { defaultBasket } from '../contexts/BasketContext';
import OrganizationContext from '../contexts/OrganizationContext';
import useSubscriptionLengthString from '../hooks/useSubscriptionLengthString';
import { AddExtraInstructors } from '../services/api';
import usePrice from '../hooks/usePrice';
import ConfirmOrderSubTotal from '../components/basket/basketComponents/ConfirmOrderSubTotal';
import EditSubscriptionStepTwo from '../components/admin/EditSubscriptionStepTwo';
import OrganizationDetailsContext from '../contexts/OrganizationDetailsContext';

const ConfirmExtraInstructors = () => {
  const { t } = useTranslation('CreateOrganization');
  const { organization, setOrganization } = useContext(OrganizationContext);
  const { numberOfExtraInstructors, requestPageOneComplete, setRequestPageOneComplete, setNumberOfExtraInstructors, setChosenSubscription } = useContext(BasketContext);
  const { addToast } = useContext(ToastContext);
  const history = useHistory();
  const billingFrequency = useSubscriptionLengthString(organization.subscription.subscriptionLength);
  const [price, subTotal, taxesIncluded] = usePrice(numberOfExtraInstructors);

  const completeExtraInstructorsOrder = () => {
    AddExtraInstructors(numberOfExtraInstructors)
      .then(() => {
        setRequestPageOneComplete(false);
        setNumberOfExtraInstructors(defaultBasket.subscriptionSize);
        setOrganization({
          ...organization,
          subscription: { ...organization.subscription, subscriptionSize: organization.subscription.subscriptionSize + numberOfExtraInstructors },
        });
        history.push('/order-complete', 'extra-instructors');
      })
      .catch((error: AxiosError) => {
        addToast(t('Could not add extra instructors. Status code:') + ' ' + error.response?.status, toastOptions.error);
      });
  };

  useEffect(() => {
    if (!requestPageOneComplete) {
      history.push('/request-extra-instructors');
    }
  }, []);

  const EditSubscription = {
    header: t('Confirm your order'),
    description: t('Confirm your order and get immediate access to the TeamReporter app. We will contact your billing representative to set up invoicing'),
    primaryContactPerson: organization.primaryContactPerson,
    billingEmail: organization.email,
    linkUrl: '/request-extra-instructors',
    requestSubscriptionRenewal: true,
    completeOrder: completeExtraInstructorsOrder,
  };

  return (
    <PageWrapper>
      <EditSubscriptionStepTwo {...EditSubscription} />
      <Card width="463px">
        <SubscriptionPrice billingFrequency={billingFrequency} instructorCount={numberOfExtraInstructors} confirmOrderPage priceToDisplay={price.oneMonth} extraUsers />
        <InvoiceInfo>{t('This is your yearly price for the requested extra instructors. Your next invoice will be prorated according to your current contract')}</InvoiceInfo>
        <ConfirmOrderSubTotal
          billingFrequency={billingFrequency}
          autoRenew={false}
          expirationDate={'expirationDate'}
          annualPriceToDisplay={subTotal}
          freeTrial={false}
          taxesIncluded={taxesIncluded}
        />
      </Card>
    </PageWrapper>
  );
};

export default ConfirmExtraInstructors;
