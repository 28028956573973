import { Button } from '@laerdal/life-react-components';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import BasketContext from '../../contexts/BasketContext';
import StepCounter from '../createOrganization/StepCounter';
import { SingleButtonWrapper } from '../layouts/InputLayouts';
import { LeftWrapper } from '../layouts/OrderFlowLayouts';
import { useHistory } from 'react-router';

interface EditSubscriptionStepOneProps {
  header: string;
  description: string;
  linkUrl: string;
  requestExtraInstructors?: boolean;
  requestSubscriptionRenewal?: boolean;
}

const EditSubscriptionStepOne = ({ header, description, linkUrl, requestExtraInstructors, requestSubscriptionRenewal }: EditSubscriptionStepOneProps) => {
  const { t } = useTranslation('Admin');
  const { setRequestPageOneComplete } = useContext(BasketContext);
  const history = useHistory();

  return (
    <LeftWrapper>
      <StepCounter currentStep={1} requestSubscriptionRenewal={requestSubscriptionRenewal} requestExtraInstructors={requestExtraInstructors} />
      <h1>{header}</h1>
      <p>{description}</p>
      <SingleButtonWrapper>
        <Button
          size="big"
          onClick={() => {
            setRequestPageOneComplete(true);
            history.push(linkUrl);
          }}>
          {t('Continue')}
        </Button>
      </SingleButtonWrapper>
    </LeftWrapper>
  );
};

export default EditSubscriptionStepOne;
