import { client } from './sanity';
import {
  LandingPageSanity,
  LandingFeature,
  LandingWideSection,
  LandingStackedSection,
  LandingHero,
  LandingFAQ,
  LandingCenteredBanner,
  PlansAndPricingContentFromSanity,
  SubscriptionPickerContentFromSanity,
  SanityLanguages,
} from '../typings/sanity';
import i18n from '../i18n';
import { Price } from '../typings/types';

export const defaultFeature: LandingFeature = {
  title: '',
  description: '',
  image: '',
  video: '',
  id: '1',
  button: {
    buttonText: 'Button',
    buttonURL: '/',
  },
};

export const defaultWideSection: LandingWideSection = {
  title: '',
  description: '',
  backgroundImage: '',
  firstLogoImage: '',
  secondLogoImage: '',
  linkText: '',
  linkUrl: '',
};

export const defaultCenteredBanner: LandingCenteredBanner = {
  title: '',
  description: '',
  button: {
    buttonText: '',
    buttonURL: '',
  },
};

export const defaultStackedSection: LandingStackedSection = {
  title: '',
  description: '',
  image: '',
};

export const defaultHeroSection: LandingHero = {
  title: '',
  backgroundVideoURL: '',
  youtubeVideoURL: '',
  primaryButton: {
    buttonText: '',
    buttonURL: '/',
  },
  secondaryButton: '',
};

export const defaultFAQSection: LandingFAQ = {
  title: '',
  description: '',
  button: {
    buttonText: '',
    buttonURL: '',
  },
};

type ValidSanityCountries = {
  countryValidation: string;
};

export const defaultLandingPage: LandingPageSanity = {
  heroSection: defaultHeroSection,
  featureIntro: defaultCenteredBanner,
  featureSections: [defaultFeature],
  standoutFeature: defaultFeature,
  wideSection: defaultWideSection,
  trialSection: defaultCenteredBanner,
  stackedSections: [defaultStackedSection],
  FAQSection: defaultFAQSection,
};

const sortEnglishSecond = (data: any) => {
  var tempData = data;
  if (data.length > 1) {
    data.map((res: SubscriptionPickerContentFromSanity | PlansAndPricingContentFromSanity, index: number) => {
      if (res.__i18n_lang === 'en' && index === 0) {
        const tempValue = { ...tempData[0] };
        tempData[0] = tempData[1];
        tempData[1] = tempValue;
      }
    });
  }
  return tempData[0];
};
export const getPrices = async (): Promise<Price[]> => {
  const query = /* groq */ `*[_type == "price" && !(_id in path('drafts.**'))]`;
  return client
    .fetch(query)
    .then((data: Price[]) => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
};

export const getPlansAndPricingContentFromSanity = () => {
  const query = /* groq */ `*[_type == "plansAndPricing" && (__i18n_lang == "${i18n.language}" || __i18n_lang == "en" && !(_id in path('drafts.**'))) ] | order(_createdAt desc) {header, subTitle, "imageUrl": image.asset->url, __i18n_lang}`;

  return new Promise<PlansAndPricingContentFromSanity>((resolve, reject) => {
    client
      .fetch(query)
      .then((data) => {
        resolve(sortEnglishSecond(data));
      })
      .catch(() => {
        return reject();
      });
  });
};

export const getSubscriptionPickerContentFromSanity = () => {
  const query = /* groq */ `*[_type == "subscriptionPicker" && (__i18n_lang == "${i18n.language}" || __i18n_lang == "en" && !(_id in path('drafts.**'))) ] | order(_createdAt asc)  {monthlyPrice, subscriptionPickerText, __i18n_lang, _id}`;

  return new Promise<SubscriptionPickerContentFromSanity>((resolve, reject) => {
    client
      .fetch(query)
      .then((data) => {
        resolve(sortEnglishSecond(data));
      })
      .catch(() => {
        return reject();
      });
  });
};
export const getLanguages = () => {
  const query = /* groq */ `*[_type == "language" && enabled == true] | order(_createdAt desc)`;
  return new Promise<SanityLanguages[]>((resolve, reject) => {
    client
      .fetch(query)
      .then((data: SanityLanguages[]) => resolve(data))
      .catch((err) => {
        console.log('Sanity catch: ' + err);
      });
  });
};

export const getLandingPage = () => {
  const query = /* groq */ `
  *[_type == "landingPage"  && (__i18n_lang == "${i18n.language}" || __i18n_lang == "en" && !(_id in path('drafts.**'))) ] | order(_createdAt desc) {
      heroSection->{"backgroundVideoURL": backgroundVideo.asset->url, "title": title, primaryButton, secondaryButton, youtubeVideoURL },
      featureIntro->{description, title}, 
      featureSections[]->{"image": image.asset->url, "video": video.asset->url, title, button, description, "id": _id},
      standoutFeature->{"image": image.asset->url, "video": video.asset->url, title, button, description},
      wideSection->{"backgroundImage": backgroundImage.asset->url, "firstLogoImage": firstLogo.asset->url, 
        "secondLogoImage": secondLogo.asset->url, title, description, linkText, "linkUrl": linkURL}, 
      stackedSections[]->{"image": image.asset->url, title, description},
      trialSection->{title, description, button},
      FAQSection->{title, description, button}
  }`;
  return new Promise<LandingPageSanity>((resolve, reject) => {
    client
      .fetch(query)
      .then((data) => {
        return resolve(data[0]);
      })
      .catch((err) => {
        console.log('Sanity catch: ' + err);
        return reject(defaultLandingPage);
      });
  });
};
export const getEnabledCountries = () => {
  const query = /* groq */ `*[_type == "validCountries"] {countryValidation}`;
  return new Promise<ValidSanityCountries>((resolve, reject) => {
    client.fetch(query).then((data) => resolve(data[0]));
  });
};
