import { Calendar, COLORS } from '@laerdal/life-react-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const AnnualSubtotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 0 24px;
  align-items: center;
`;

const LeftContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AnnualSubtotalText = styled.p`
  font-size: 16px;
  color: ${COLORS.neutral_500};
  margin: 0;
`;

const RightContent = styled.div`
  text-align: right;
`;

const NoMarginBigText = styled.p`
  margin: 0;
  font-size: 22px;
  color: ${COLORS.neutral_700};
`;

const LeftContentTextWrapper = styled.div`
  margin-left: 15px;
`;

interface ConfirmOrderSubTotalProps {
  freeTrial: boolean;
  expirationDate: string;
  annualPriceToDisplay: string;
  billingFrequency: string;
  autoRenew: boolean;
  taxesIncluded: boolean;
}

const ConfirmOrderSubTotal = ({ freeTrial, expirationDate, annualPriceToDisplay, billingFrequency, autoRenew, taxesIncluded }: ConfirmOrderSubTotalProps) => {
  const { t } = useTranslation('CreateOrganization');
  return (
    <AnnualSubtotalWrapper>
      <LeftContent>
        <Calendar size={'32px'} />
        <LeftContentTextWrapper>
          <AnnualSubtotalText>
            {freeTrial ? t('Free trial valid until') : billingFrequency} {autoRenew && !freeTrial && ' (' + t('evergreen') + ')'}{' '}
          </AnnualSubtotalText>
          <NoMarginBigText>{freeTrial && expirationDate}</NoMarginBigText>
        </LeftContentTextWrapper>
      </LeftContent>
      <RightContent>
        <AnnualSubtotalText>{freeTrial || taxesIncluded ? t('Subtotal') : t('Subtotal (excl. taxes)')}</AnnualSubtotalText>
        <NoMarginBigText>{freeTrial ? '0' : annualPriceToDisplay}</NoMarginBigText>
      </RightContent>
    </AnnualSubtotalWrapper>
  );
};

export default ConfirmOrderSubTotal;
