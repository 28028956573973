import React, { useState } from 'react';
import { Industry, EditableOrganization } from '../typings/types';

interface Props {
  children: any;
}

interface OrganizationDetailsProps {
  editOrganization: EditableOrganization;
  setEditOrganization: React.Dispatch<React.SetStateAction<EditableOrganization>>;
}

const OrganizationDetailsContext = React.createContext<OrganizationDetailsProps>({} as OrganizationDetailsProps);

export const OrganizationDetailsProvider: React.FunctionComponent<Props> = (props) => {
  const [editOrganization, setEditOrganization] = useState<EditableOrganization>({
    name: '',
    domain: '',
    billingName: '',
    industry: Industry.null,
    address: {
      addressLine: '',
      zipCode: '',
      country: { name: '', codeAlpha2: '', codeAlpha3: '', states: [{ name: '', code: '' }] },
      state: { name: '', code: '' },
      city: '',
    },
    billingAddress: {
      addressLine: '',
      zipCode: '',
      country: { name: '', codeAlpha2: '', codeAlpha3: '', states: [{ name: '', code: '' }] },
      state: { name: '', code: '' },
      city: '',
    },
    email: '',
    primaryContactPerson: '',
    vatNumber: '',
    useSameAddress: false,
  });

  return (
    <OrganizationDetailsContext.Provider
      value={{
        editOrganization,
        setEditOrganization,
      }}>
      {props.children}
    </OrganizationDetailsContext.Provider>
  );
};

export default OrganizationDetailsContext;
