import React, { useContext, useEffect } from 'react';
import OrganizationDetailsContext from '../../contexts/OrganizationDetailsContext';
import { useTranslation } from 'react-i18next';
import { CheckboxOn, CheckboxOff, COLORS, Button } from '@laerdal/life-react-components';
import { CountryAndStateFields } from './CountryAndStateDropdown';
import { useHistory } from 'react-router';
import OrganizationContext from '../../contexts/OrganizationContext';
import { ButtonWrapper, CheckboxWrapper, IconPointer, InputField, StyledLabel } from '../layouts/InputLayouts';
import { Country, State } from '../../typings/types';

const BillingDetailsForm = () => {
  const { t } = useTranslation('CreateOrganization');
  const history = useHistory();
  const { organization: org } = useContext(OrganizationContext);
  const { countries } = useContext(OrganizationContext);
  const { editOrganization, setEditOrganization } = useContext(OrganizationDetailsContext);
  const setBillingAddressToOrgAddress = () => {
    if (!org.useSameAddress) {
      const editableOrganization = { ...editOrganization };
      editableOrganization.useSameAddress = !editableOrganization.useSameAddress;
      setEditOrganization(editableOrganization);
    }
  };
  const handleChange = (
    value: string,
    firstParameter: 'billingAddress' | 'country' | 'state' | 'billingName' | 'primaryContactPerson' | 'email',
    secondParameter?: 'zipCode' | 'city' | 'addressLine',
  ) => {
    const editableOrganization = { ...editOrganization };
    if (firstParameter === 'billingAddress') {
      editableOrganization[firstParameter][secondParameter as 'zipCode' | 'city' | 'addressLine'] = value;
    } else if (firstParameter === 'country') {
      const selectedCountry = countries.find((country) => country.name === value) as Country;
      editableOrganization.billingAddress.country = selectedCountry;
    } else if (firstParameter === 'state') {
      const selectedState = editOrganization.billingAddress.country.states.find((state) => state.name === value) as State;
      editableOrganization.billingAddress.state = selectedState;
    } else {
      editableOrganization[firstParameter] = value;
    }

    setEditOrganization(editableOrganization);
  };
  useEffect(() => {
    !editOrganization.domain && history.push('create-organization');
  }, []);

  return (
    <form
      onSubmit={() => {
        history.push('/confirm-order');
        window.scrollTo(0, 0);
      }}>
      <StyledLabel>{t('Organization name')}</StyledLabel>
      <InputField
        value={editOrganization.billingName}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, 'billingName')}
        placeholder={'Laerdal Medical Corporation'}
        disabled={!!org.billingName}
        required
      />
      <StyledLabel>{t('Primary contact person')}</StyledLabel>
      <InputField
        value={editOrganization.primaryContactPerson}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, 'primaryContactPerson')}
        placeholder={'Kari Nordmann'}
        disabled={!!org.primaryContactPerson}
        required
      />
      <StyledLabel>{t('Billing email address')}</StyledLabel>
      <InputField
        value={editOrganization.email}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, 'email')}
        placeholder={'post@accounting.com'}
        disabled={!!org.email}
        required
        type="email"
      />
      <StyledLabel>{t('Billing address')}</StyledLabel>
      <CheckboxWrapper>
        <IconPointer
          onClick={setBillingAddressToOrgAddress}
          onKeyUp={(e: React.KeyboardEvent) => {
            if (e.key === ' ' || e.key === 'Spacebar' || e.key === 'Enter') {
              setBillingAddressToOrgAddress();
            }
          }}
          tabIndex={0}>
          {org.useSameAddress ? (
            <CheckboxOn size="26px" color={COLORS.neutral_300} />
          ) : editOrganization.useSameAddress ? (
            <CheckboxOn size="26px" color={COLORS.primary_500} />
          ) : (
            <CheckboxOff size="26px" color={COLORS.neutral_400} />
          )}
          <p>{t('Same as organization address')}</p>
        </IconPointer>
      </CheckboxWrapper>

      {!editOrganization.useSameAddress && (
        <>
          <CountryAndStateFields
            country={editOrganization.billingAddress.country}
            handleChange={handleChange}
            disabled={editOrganization.useSameAddress || (!!org.billingAddress && !!org.billingAddress.country?.name)}
            stateDisabled={editOrganization.useSameAddress || (!!org.billingAddress && !!org.billingAddress.state)}
            state={editOrganization.billingAddress.state}
            showStateField={true}
          />
          <StyledLabel>{t('Zip or postal code')}</StyledLabel>
          <InputField
            value={editOrganization.billingAddress.zipCode}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, 'billingAddress', 'zipCode')}
            placeholder={'4013'}
            required
            disabled={editOrganization.useSameAddress || (!!org.address && !!org.billingAddress && !!org.billingAddress.zipCode)}
          />
          <StyledLabel>{t('City')}</StyledLabel>
          <InputField
            value={editOrganization.billingAddress.city}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, 'billingAddress', 'city')}
            placeholder={'Stavanger'}
            required
            disabled={editOrganization.useSameAddress || (!!org.billingAddress && !!org.billingAddress.city)}
          />
          <StyledLabel>{t('Address')}</StyledLabel>
          <InputField
            value={editOrganization.billingAddress.addressLine}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, 'billingAddress', 'addressLine')}
            placeholder={'Haakons street 17'}
            required
            disabled={editOrganization.useSameAddress || !!org.billingAddress?.addressLine}
          />
        </>
      )}
      <ButtonWrapper>
        <Button variant="secondary" size={'big'} onClick={() => history.push('/create-organization')}>
          {t('Back')}
        </Button>
        <Button type="submit" variant="primary" size={'big'}>
          {t('Continue')}
        </Button>
      </ButtonWrapper>
    </form>
  );
};

export default BillingDetailsForm;
