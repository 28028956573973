import { ToastColor, ToastPosition } from '@laerdal/life-react-components';

const toastOptions = {
  error: {
    color: ToastColor.RED,
    showCloseButton: true,
    autoClose: true,
    position: ToastPosition.TOPMIDDLE,
    delay: 10000,
  },
  success: {
    color: ToastColor.GREEN,
    showCloseButton: true,
    autoClose: true,
    position: ToastPosition.TOPMIDDLE,
    delay: 10000,
  },
  contact: [
    {
      label: 'contact us',
      handler: () => window.open('https://www.laerdal.com/no/support/customer-service/contact-us/', '_blank'),
    },
  ],
  errorClose: {
    color: ToastColor.RED,
    showCloseButton: true,
    autoClose: false,
    position: ToastPosition.TOPMIDDLE,
    delay: 10000,
  },
};

export default toastOptions;
