import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import './axios';
import './i18n';
import TagManager from 'react-gtm-module';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

if (process.env.REACT_APP_GTM_ID && process.env.GTM_ID !== '' && process.env.REACT_APP_GTM_ID.substring(0, 4) === 'GTM-') {
  // Initialize Google Tag manager
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
  };
  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
  <Suspense fallback={<div> Loading...</div>}>
    <App />
  </Suspense>,
  document.getElementById('root'),
);
