import React, { useContext, useRef, useState } from 'react';
import { GlobalNavigationBar, ActionOptions, COLORS, NavOption } from '@laerdal/life-react-components';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../contexts/AuthContext';
import styled from 'styled-components';
import UserMenu from './UserMenu';
import OrganizationContext from '../../contexts/OrganizationContext';
import LanguageMenu from './LanguageMenu';
import { SubscriptionLevel } from '../../typings/types';

const MenuWrapper = styled.div<MenuWrapperProps>`
  background-color: ${COLORS.white};
  display: flex;
  margin-left: ${(props) => (props.floatRight ? 'auto' : 'unset')};
  position: absolute;
  right: 25%;
  height: 0;
  width: 0;
  &:focus-within,
  &:hover,
  &.open {
    background: ${COLORS.white};
  }
`;

const NavBarWrapper = styled.div`
  z-index: 2;
  padding-left: calc(100vw - 100%);
`;

const Overlay = styled.div`
  position: fixed;
  top: 65px;
  left: 0;
  height: 100vh;
  width: 100vw;
  opacity: 0;
  background-color: red;
  z-index: 4;
`;
interface MenuWrapperProps {
  floatRight: boolean;
}

const NavBar = () => {
  const { t } = useTranslation('Navigation');
  const { user, logout } = useContext(AuthContext);
  const { organization } = useContext(OrganizationContext);
  const menuRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);
  const handleClickMenuAction = () => {
    setShowMenu(false);
    setShowLanguageDropdown(false);
  };

  const headerActions: ActionOptions = {
    language: () => {
      setShowLanguageDropdown(!showLanguageDropdown);
      setShowMenu(false);
    },
    user: () => {
      setShowMenu(!showMenu);
      setShowLanguageDropdown(false);
    },
    apps: () => {
      // @ts-ignore
      window.globalNav.toggle();
    },
  };

  const GetMainNavigationOptions = () => {
    const options: NavOption[] = [];
    if (!!organization?.domain) {
      options.push({ label: t('Admin'), to: '/admin' });
      if (organization?.subscription?.subscriptionLevel !== SubscriptionLevel.Professional) options.push({ label: t('Plans and pricing'), to: '/plans-and-pricing' });
    } else {
      options.push({ label: t('Plans and pricing'), to: '/plans-and-pricing' });
    }
    options.push({ label: t('Contact us'), to: '/contact-us' });
    return options;
  };

  const GetSecondaryNavigationOptions = (): NavOption[] => {
    const secondaryNavigationOptions = [];

    if (!user.isAuthenticated) {
      secondaryNavigationOptions.push({
        label: t('Sign in'),
        to: '/sign-in',
      });
    }

    return secondaryNavigationOptions;
  };

  const GetHeaderActions: () => ActionOptions = () => {
    return user.isAuthenticated
      ? headerActions
      : {
          language: () => {
            setShowLanguageDropdown(!showLanguageDropdown);
            setShowMenu(false);
          },
        };
  };

  return (
    <NavBarWrapper>
      {(showMenu || showLanguageDropdown) && (
        <Overlay
          onClick={() => {
            setShowMenu(false);
            setShowLanguageDropdown(false);
          }}></Overlay>
      )}
      <GlobalNavigationBar
        name={'TeamReporter'}
        mainNavigationOptions={GetMainNavigationOptions()}
        secondaryNavigationOptions={GetSecondaryNavigationOptions()}
        organizationName={organization?.name}
        reverseRightSideOrder={true}
        actions={GetHeaderActions()}
        firstName={user.firstName}
        lastName={user.lastName}
        email={user.email}
        //Set to "true" so it doesn't all disappear when user is not logged in.
        isAuthenticated={true}
        signout={user.isAuthenticated ? logout : undefined}
        signOutLabel={t('Sign out')}
        mobileActions={GetHeaderActions()}
        accountMenuHeader={t('Account')}
        switcherAction={() => {
          // @ts-ignore
          window.globalNav.toggle();
        }}
        useUserMenu={true}
      />
      <MenuWrapper ref={menuRef} className={showLanguageDropdown ? 'open' : ''} floatRight={false}>
        <LanguageMenu clickMenuAction={handleClickMenuAction} />
      </MenuWrapper>
    </NavBarWrapper>
  );
};

export default NavBar;
