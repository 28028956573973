import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { COLORS } from '@laerdal/life-react-components';
import usePrice from '../../../hooks/usePrice';

const SubscriptionPriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
`;
const PriceInfoWrapper = styled.div``;

const PriceText = styled.h1`
  font-size: 48px;
  font-weight: 300;
  margin: 0;
`;

const BillingFrequency = styled.p`
  font-size: 14px;
  font-weight: 300;
  color: ${COLORS.neutral_800};
  margin: 0;
`;

const Badge = styled.div`
  height: fit-content;
  background-color: ${COLORS.primary_500};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 8px 8px;
  text-align: center;
  padding: 8px 12px 16px 12px;
  color: white;
  margin-top: -30px;
`;

const BadgeTopText = styled.p`
  font-size: 10px;
  margin: 0;
`;

const BadgeMiddleText = styled.p`
  font-size: 20px;
  font-weight: 700;
  margin: 2px 0 5px 0;
`;

const BadgeBottomText = styled.p`
  font-size: 7px;
  margin: 0;
`;

const SmallFont = styled.span`
  font-size: 18px;
  font-weight: 300;
`;

const SubscriptionLevelText = styled.h2`
  font-weight: 300;
  font-size: 32px;
  margin: 0;
`;
export const SpacerWithBorder = styled.div`
  width: 80px;
  height: 40px;
  border-bottom: 1px solid ${COLORS.neutral_300};
`;

const YourSelectionText = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: ${COLORS.primary_500};
  margin: 0 0 10px 0;
`;

interface SubscriptionPriceProps {
  priceToDisplay: string;
  confirmOrderPage?: boolean;
  freeTrial?: boolean;
  instructorCount: number;
  billingFrequency: string;
  extraUsers?: boolean;
}

const SubscriptionPrice = ({ priceToDisplay, confirmOrderPage, freeTrial, instructorCount, billingFrequency, extraUsers }: SubscriptionPriceProps) => {
  const { t } = useTranslation('CreateOrganization');

  return (
    <SubscriptionPriceWrapper>
      <PriceInfoWrapper>
        {confirmOrderPage && <YourSelectionText>{t('Your selection')}</YourSelectionText>}
        <SubscriptionLevelText>{freeTrial ? t('Trial') : t('Pro')}</SubscriptionLevelText>
        <PriceText>
          {priceToDisplay === '' ? '$ -' : priceToDisplay}
          {priceToDisplay !== '' && <SmallFont>{t('/month')}</SmallFont>}
        </PriceText>
        <BillingFrequency>{freeTrial ? t('30 days FREE trial') : t('Billed') + billingFrequency}</BillingFrequency>
        {!confirmOrderPage && <SpacerWithBorder />}
      </PriceInfoWrapper>
      <Badge>
        <BadgeTopText>{extraUsers ? t('EXTRA') : t('FOR')}</BadgeTopText>
        <BadgeMiddleText>{instructorCount}</BadgeMiddleText>
        <BadgeBottomText>{instructorCount > 1 ? t('INSTRUCTORS') : t('INSTRUCTOR')}</BadgeBottomText>
      </Badge>
    </SubscriptionPriceWrapper>
  );
};

export default SubscriptionPrice;
