import React, { useContext, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { CheckComplete, COLORS } from '@laerdal/life-react-components';
import i18n from '../../i18n';
import { client } from '../../services/sanity';
import AuthContext from '../../contexts/AuthContext';
import { Menu, MenuSection, MenuSectionList } from '../layouts/NavigationLayouts';
import { SanityLanguages } from '../../typings/sanity';
import { getLanguages } from '../../services/sanityCalls';

interface LanguageDropdownProps {
  currentLanguage: boolean;
}

const Check = styled(CheckComplete)`
  size: 16px;
  margin-right: 5px;
`;

const MenuLink = styled.div<LanguageDropdownProps>`
  border: none;
  background: ${(props) => (props.currentLanguage ? 'rgb(150, 150, 150, 0.1)' : 'transparent')};
  /* background: transparent; */
  padding: 15px 20px 15px 35px;
  color: ${COLORS.black};
  text-decoration: none;
  position: relative;
  :hover {
    background-color: rgb(150, 150, 150, 0.1);
    cursor: pointer;
  }
  & svg {
    position: absolute;
    left: 10px;
    top: 15px;
  }
`;
const LanguageMenu = ({ clickMenuAction }: { clickMenuAction: () => void }) => {
  const [languages, setLanguages] = useState<Array<SanityLanguages>>([]);
  const { setUser, user } = useContext(AuthContext);

  useEffect(() => {
    getLanguages().then((data) => setLanguages(data));
  }, []);

  //Renders a dropdown of all active languages in sanity
  const renderLanguages = () => {
    let isLanguageEnabled = false;
    if (languages.length > 0) {
      const sanityLanguages = languages.map((language) => {
        if (i18n.language === language._id) isLanguageEnabled = true;
        return (
          <MenuLink
            currentLanguage={language._id === i18n.language}
            key={language._id}
            onClick={(e) => {
              e.preventDefault();
              i18n.changeLanguage(language._id);
              setUser({ ...user, language: language._id });
              clickMenuAction();
            }}>
            <div>
              {language._id === i18n.language && <Check size="16px" />}
              {language.name}
            </div>
          </MenuLink>
        );
      });
      //If languages has been disabled reset language
      if (!isLanguageEnabled) {
        i18n.changeLanguage('en');
        setUser({ ...user, language: 'en' });
      }

      return sanityLanguages;
    }
  };

  return (
    <Menu onClick={clickMenuAction} role="menu" aria-labelledby="UserMenuButton">
      <MenuSection>
        <MenuSectionList>{renderLanguages()}</MenuSectionList>
      </MenuSection>
    </Menu>
  );
};

export default LanguageMenu;
