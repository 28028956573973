import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AuthPage, screenSetsErrorMessages_EN, ToastContext } from '@laerdal/life-react-components';
import SignInPlaceholderImage from '../assets/images/portal-sign-in-placeholder.svg';
import SignUpPlaceholderImage from '../assets/images/portal-sign-up-placeholder.svg';
import AuthContext from '../contexts/AuthContext';
import { useHistory } from 'react-router';
import { GetOrganization } from '../services/api';
import OrganizationContext from '../contexts/OrganizationContext';
import { SubscriptionLevel } from '../typings/types';
import { addErrorToast } from '../services/functions';
import i18n from '../i18n';

const Main = styled.main`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: -20px;
  height: calc(100vh - 65px);
`;

interface LoginProps {
  firstScreen?: string;
  children: any;
}
const SignIn: React.FunctionComponent<LoginProps> = (props) => {
  const { firstScreen } = props;
  const { t } = useTranslation('SignIn');
  const [registration, setRegistration] = useState<boolean>(false);
  const { user, setUser, intent } = useContext(AuthContext);
  const { setOrganization, setOrgLoad } = useContext(OrganizationContext);
  const history = useHistory();
  const { addToast } = useContext(ToastContext);

  const tieImageToScreen = (e: any) => {
    if (e?.nextScreen === 'gigya-register-screen') {
      setRegistration(true);
    } else {
      setRegistration(false);
    }
  };

  useEffect(() => {
    !user.isAuthenticated &&
      // @ts-ignore
      window.gigya.accounts.showScreenSet({
        screenSet: 'Default-RegistrationLogin',
        containerID: 'gigya-container-mlp',
        startScreen: firstScreen,
        onBeforeScreenLoad: tieImageToScreen,
        customLang: screenSetsErrorMessages_EN,
        lang: i18n.language.split('-')[0],
      });
  }, [firstScreen, user.isAuthenticated, i18n.language]);

  useEffect(() => {
    if (user.isAuthenticated) {
      setOrgLoad(true);
      GetOrganization()
        .then((org) => {
          !!org && setOrganization(org);
          setOrgLoad(false);
          if (org.subscription?.subscriptionLevel === SubscriptionLevel.Professional) {
            //1 User has pro subscription. Redirect to admin
            history.push('/admin');
          } else if (org.subscription?.subscriptionLevel === SubscriptionLevel.Trial) {
            //2 User has Teamreporter Organization with trial subscription
            intent === '/create-organization' ? history.push(intent) : history.push('/admin');
          } else {
            //3 User has Laerdal Organization, but is not registered in TeamReporter
            //4 User has no Organization with Teamreporter, and no Organization with Laerdal
            intent.includes('create') ? history.push(intent) : history.push('/');
          }
        })
        .catch((error) => {
          setOrgLoad(false);
          // 5. Other error
          addErrorToast(t, addToast, error);
          history.push('/');
        });
    }
  }, [user, setOrganization, setUser]);

  return (
    <Main>
      <AuthPage
        showInformationSection={true}
        signUp={registration}
        signUpPlaceholderImage={SignUpPlaceholderImage}
        signInPlaceholderImage={SignInPlaceholderImage}
        signUpPlaceholderImageAlt={t('Man and woman gesticulating while another man is sitting down with a tablet')}
        signInPlaceholderImageAlt={t('Man and woman gesticulating. The woman points to the sign-in form on the right')}
        signUpInformationHeader={t('One account. All of Laerdal.')}
        signInInformationHeader={t('Welcome to Laerdal')}
        signUpInformationText={t('Create a Laerdal account today to access these services')}
        signInInformationText={t('Sign in with your account')}
        screenSetsContainerId="gigya-container-mlp"
      />
    </Main>
  );
};

SignIn.defaultProps = {
  firstScreen: 'gigya-login-screen',
};

export default SignIn;
