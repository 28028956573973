import React, { SetStateAction } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CheckboxWrapper, IconPointer } from '../layouts/InputLayouts';
import { CheckboxOn, CheckboxOff } from '@laerdal/life-react-components';
import { COLORS } from '@laerdal/life-react-components';
import { Link } from 'react-router-dom';

const ThinTitle = styled.h2`
  font-weight: 300;
`;

const TermsText = styled.p`
  margin: 0;
`;

const LicenseRenewalTextWrapper = styled.div`
  padding-left: 15px;
`;

const TermsLink = styled(Link)`
  font-weight: 700;
  color: ${COLORS.primary_500};
  text-decoration: none;
`;

interface TermsCheckboxProps {
  agreeToTerms: boolean;
  setAgreeToTerms: React.Dispatch<SetStateAction<boolean>>;
}

const TermsCheckbox = (props: TermsCheckboxProps) => {
  const { t } = useTranslation('CreateOrganization');
  return (
    <>
      <ThinTitle>{t('Terms and conditions')}</ThinTitle>
      <CheckboxWrapper>
        <IconPointer
          onClick={() => props.setAgreeToTerms(!props.agreeToTerms)}
          onKeyUp={(e) => {
            if (e.key === ' ' || e.key === 'Spacebar' || e.key === 'Enter') {
              props.setAgreeToTerms(!props.agreeToTerms);
            }
          }}
          tabIndex={0}>
          {props.agreeToTerms ? <CheckboxOn size="26px" color={COLORS.primary_500} /> : <CheckboxOff size="26px" color={COLORS.neutral_400} />}
          <LicenseRenewalTextWrapper>
            <TermsText>
              {t('I agree to the ')}
              <TermsLink to={'/terms-and-conditions'} target="_blank">
                {t('Laerdal TeamReporter terms and conditions.')}
              </TermsLink>
            </TermsText>
          </LicenseRenewalTextWrapper>
        </IconPointer>
      </CheckboxWrapper>
    </>
  );
};

export default TermsCheckbox;
