import { useContext, useEffect, useState } from 'react';
import OrganizationDetailsContext from '../contexts/OrganizationDetailsContext';
import { Price, PriceValues } from '../typings/types';
import * as sanity from '../services/sanityCalls';
import * as api from '../services/api';
import BasketContext from '../contexts/BasketContext';
import OrganizationContext from '../contexts/OrganizationContext';
import { ToastContext } from '@laerdal/life-react-components';
import toastOptions from '../assets/ToastOptions';
import { useTranslation } from 'react-i18next';
import AuthContext from '../contexts/AuthContext';

const defaultPrices = {
  freeTrial: '',
  oneMonth: '',
  oneYear: '',
  threeYears: '',
  fiveYears: '',
};
const usePrice = (size = 1) => {
  const { chosenSubscription, prices, setPrices } = useContext(BasketContext);
  const { t } = useTranslation('CreateOrganization');
  const { editOrganization } = useContext(OrganizationDetailsContext);
  const { organization, orgLoad } = useContext(OrganizationContext);
  const { user } = useContext(AuthContext);
  const { addToast } = useContext(ToastContext);
  const [price, setPrice] = useState(defaultPrices);
  const [subTotal, setSubTotal] = useState('');
  const [currency, setCurrency] = useState('');
  const [currencyHasChanged, setCurrencyHasChanged] = useState(false);
  // For Germany, the price from Sanity is including taxes. taxesIncluded will be used to determine if the text says the price is including/excluding taxes.
  const [taxesIncluded, setTaxesIncluded] = useState(false);
  //Formats to the correct currency format
  const currencyFormatter = (price: Price) => {
    let subTotal = price.value * size;
    if (currency !== '' && currency !== price.currency) {
      setCurrencyHasChanged(true);
    }
    setCurrency(price.currency);
    return {
      freeTrial: new Intl.NumberFormat(price.languageCountryCode, { style: 'currency', currency: price.currency, maximumSignificantDigits: 15 }).format(0),
      oneMonth: new Intl.NumberFormat(price.languageCountryCode, { style: 'currency', currency: price.currency, maximumSignificantDigits: 15 }).format(subTotal),
      oneYear: new Intl.NumberFormat(price.languageCountryCode, { style: 'currency', currency: price.currency, maximumSignificantDigits: 15 }).format(subTotal * 12),
      threeYears: new Intl.NumberFormat(price.languageCountryCode, { style: 'currency', currency: price.currency, maximumSignificantDigits: 15 }).format(subTotal * 12 * 3),
      fiveYears: new Intl.NumberFormat(price.languageCountryCode, { style: 'currency', currency: price.currency, maximumSignificantDigits: 15 }).format(subTotal * 12 * 5),
    };
  };

  //Function that gets and sets sanity prices
  const getSanityPrices = () => {
    if (prices.length === 0) {
      sanity
        .getPrices()
        .then((res) => {
          setPrices(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  //Function that gets and sets users countryCode if not already set
  const getGeoLocation = () => {
    if (sessionStorage.getItem('countryCode') == null) {
      api
        .GetGeoLocation()
        .then((response) => {
          sessionStorage.setItem('countryCode', response);
        })
        .catch((err) => console.log(err));
    }
  };

  //Function that finds the price dependent on countrycode
  const findPrice = (code: string | null) => {
    if (code !== '' && code !== null) {
      const priceBasedOnISOCode = prices.find((price) => price.ISOCode == code);
      if (priceBasedOnISOCode != null) {
        setPrice(currencyFormatter(priceBasedOnISOCode));
        setTaxesIncluded(code === 'DE');
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    getSanityPrices();
    getGeoLocation();
  }, []);

  useEffect(() => {
    if (currencyHasChanged) {
      addToast(t('The currency has changed based on your country selection. Please check your basket.'), toastOptions.success);
      setCurrencyHasChanged(false);
    }
  }, [currencyHasChanged]);

  useEffect(() => {
    if ((user.userFetchComplete && !user.isAuthenticated) || !orgLoad) {
      //First check for country, return if country gives us a price
      if (findPrice(organization.address?.country?.codeAlpha2)) return;
      if (findPrice(editOrganization.address.country.codeAlpha2)) return;
      //Second check for sessionStorage, return if countryCode gives us a price
      if (findPrice(sessionStorage.getItem('countryCode'))) return;
      //Third check for US, return if US gives us a price (default)
      if (findPrice('US')) return;
      //Sanity does not work return 0
      if (prices.length === 0) {
        setPrice(defaultPrices);
        setTaxesIncluded(false);
      }
    }
  }, [editOrganization.address.country, prices, size, organization, user, orgLoad]);

  useEffect(() => {
    getSubtotal();
  }, [price, chosenSubscription.subscriptionLength]);

  const getSubtotal = () => {
    if (chosenSubscription.subscriptionLength === 1) {
      setSubTotal(price.oneYear);
    } else if (chosenSubscription.subscriptionLength === 3) {
      setSubTotal(price.threeYears);
    } else {
      setSubTotal(price.fiveYears);
    }
  };

  return [price, subTotal, taxesIncluded] as [PriceValues, string, boolean];
};

export default usePrice;
