import axios, { AxiosError } from 'axios';
import { trackPromise } from 'react-promise-tracker';
import i18n from '../i18n';

import { ContactUsForm, Country, Order, Organization, OrganizationMember, Subscription, TrialOrder } from '../typings/types';

export const GetCountryList = (): Promise<Country[]> => {
  return axios
    .get('/assets/countries')
    .then((response) => Promise.resolve(response.data.slice(1)))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const PostOrder = (order: Order | TrialOrder): Promise<Organization> => {
  return trackPromise(
    axios
      .post('/order', { ...order, language: i18n.language })
      .then((response) => {
        if (response.data?.useSameAddress) {
          response.data.billingAddress = response.data.address;
        }
        return Promise.resolve(response.data);
      })
      .catch((error: AxiosError) => Promise.reject(error)),
  );
};

export const GetOrganization = (): Promise<Organization> => {
  return trackPromise(
    axios
      .get('/organizations')
      .then((response) => {
        if (response.data?.useSameAddress) {
          response.data.billingAddress = response.data.address;
        }
        return Promise.resolve(response.data);
      })
      .catch((error: AxiosError) => Promise.reject(error)),
  );
};

export const PostAppUser = (appUser: OrganizationMember): Promise<OrganizationMember> => {
  return trackPromise(
    axios
      .post('/users', { user: appUser, language: i18n.language })
      .then((response) => Promise.resolve(response.data))
      .catch((error: AxiosError) => Promise.reject(error)),
  );
};

export const DeleteAppUser = (id: string): Promise<OrganizationMember> => {
  return trackPromise(
    axios
      .delete('/users/' + id)
      .then((response) => Promise.resolve(response.data))
      .catch((error: AxiosError) => Promise.reject(error)),
  );
};

export const IsDomainInUse = (domainName: string): Promise<boolean> => {
  return axios
    .get('/Organizations/domain/' + domainName)
    .then((response) => Promise.resolve(response.data))
    .catch(() => Promise.reject());
};

export const AddExtraInstructors = (numberOfExtraInstructors: number): Promise<void> => {
  return trackPromise(
    axios
      .post('/order/add-instructors', { numberOfExtraInstructors, language: i18n.language })
      .then(() => Promise.resolve())
      .catch((error: AxiosError) => Promise.reject(error)),
  );
};

export const GetGeoLocation = (): Promise<string> => {
  return axios
    .get('/locations/geoLocation')
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error: AxiosError) => {
      console.log(error);
      return Promise.reject();
    });
};

export const PostContactUsForm = (contactUsForm: ContactUsForm): Promise<void> => {
  let response: Promise<void>;
  trackPromise(
    (response = axios
      .post('/contactus', contactUsForm, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(() => Promise.resolve())
      .catch((error: AxiosError) => Promise.reject(error))),
  );
  return response;
};

export const ChangeAutoRenew = (autoRenew: boolean): Promise<Subscription> => {
  let response: Promise<Subscription>;
  trackPromise(
    (response = axios
      .post(
        '/subscriptions/change-auto-renew',
        { autoRenew, language: i18n.language },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => Promise.resolve(response.data))
      .catch((error: AxiosError) => Promise.reject(error))),
  );
  return response;
};

export const RenewSubscription = (subscription: Subscription): Promise<Organization> => {
  let response: Promise<Organization>;
  trackPromise(
    (response = axios
      .post(
        '/subscriptions/renew-subscription',
        { subscription, language: i18n.language },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => Promise.resolve(response.data))
      .catch((error: AxiosError) => Promise.reject(error))),
  );
  return response;
};

export const ActivateUser = (userId: string): Promise<OrganizationMember> => {
  let response: Promise<OrganizationMember>;
  trackPromise(
    (response = axios
      .post('/users/activate-user/' + userId)
      .then((response) => Promise.resolve(response.data))
      .catch((error: AxiosError) => Promise.reject(error))),
  );
  return response;
};
