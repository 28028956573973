import React, { useState, SetStateAction, useEffect, useContext } from 'react';
import { Organization, Industry, Country } from '../typings/types';
import { GetCountryList, GetOrganization } from '../services/api';
import AuthContext from './AuthContext';
import { defaultBasket } from './BasketContext';
import { useTranslation } from 'react-i18next';
import { ToastContext } from '@laerdal/life-react-components';
import { addErrorToast } from '../services/functions';

const defaultOrganization = {
  id: '',
  industry: Industry.null,
  name: '',
  domain: '',
  billingName: '',
  address: {
    addressLine: '',
    zipCode: '',
    country: {
      name: '',
      codeAlpha2: '',
      codeAlpha3: '',
    },
    state: '',
    city: '',
  },
  billingAddress: {
    addressLine: '',
    zipCode: '',
    country: {
      name: '',
      codeAlpha2: '',
      codeAlpha3: '',
    },
    state: '',
    city: '',
  },
  email: '',
  subscription: defaultBasket,
  members: [],
  vatNumber: '',
  primaryContactPerson: '',
  useSameAddress: false,
};

type Props = {
  children?: any;
};
const defaultCountryList: Country[] = [{ name: '', codeAlpha2: '', codeAlpha3: '', states: [{ name: '', code: '' }] }];

interface OrganizationProps {
  organization: Organization;
  countries: Country[];
  setOrganization: React.Dispatch<SetStateAction<Organization>>;
  clearOrganization: () => void;
  setCountries: (countries: Country[]) => void;
  setOrgLoad: (loading: boolean) => void;
  orgLoad: boolean;
}
const OrganizationContext = React.createContext<OrganizationProps>({
  countries: defaultCountryList,
  organization: defaultOrganization,
  setOrganization: () => {},
  clearOrganization: () => {},
  setCountries: () => {},
  setOrgLoad: () => {},
  orgLoad: true,
});

export const OrganizationProvider: React.FC = ({ children }: Props) => {
  const [organization, setOrganization] = useState<Organization>(defaultOrganization);
  const [orgLoad, setOrgLoad] = useState(true);
  const [countries, setCountries] = useState<Country[]>([{ name: '', codeAlpha2: '', codeAlpha3: '', states: [{ name: '', code: '' }] }]);
  const { setUser, user } = useContext(AuthContext);
  const { addToast } = useContext(ToastContext);
  const { t } = useTranslation('CreateOrganization');

  const clearOrganization = () => {
    setOrganization(defaultOrganization);
  };
  useEffect(() => {
    GetCountryList().then((list: Country[]) => {
      setCountries(list);
    });
  }, []);
  useEffect(() => {
    if (user.isAuthenticated && !organization.domain) {
      setOrgLoad(true);
      GetOrganization()
        .then((org) => {
          if (!!org) {
            setOrganization(org);
            setOrgLoad(false);
          } else {
            setOrganization(defaultOrganization);
            setOrgLoad(false);
          }
        })
        .catch((error) => {
          addErrorToast(t, addToast, error);
          setOrgLoad(false);
        });
    }
  }, [setOrganization, setUser, user.isAuthenticated]);

  return (
    <OrganizationContext.Provider value={{ organization, countries, setOrganization, clearOrganization, setCountries, orgLoad, setOrgLoad }}>
      {children}
    </OrganizationContext.Provider>
  );
};

export default OrganizationContext;
