import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import OrganizationContext from '../../contexts/OrganizationContext';
import { SubscriptionLevel } from '../../typings/types';
import { Button, Close, ToastContext } from '@laerdal/life-react-components';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { InfoWrapper, RedButton } from '../layouts/AdminLayouts';
import Modal from 'react-modal';
import modalStyles from '../../styles/modalStyles';
import { ClickableIconWrapper, ModalButtonWrapper, ModalTopSection } from '../layouts/ModalLayout';
import * as api from './../../services/api';
import toastOptions from '../../assets/ToastOptions';

const BigButtonWrapper = styled.div`
  display: flex;
  @media (min-width: 768px) {
    flex-direction: row;
    & :nth-child(2) {
      margin-left: 30px;
    }
  }
  flex-direction: column;
`;

const SubscriptionManagementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  p {
    font-weight: 700;
  }
`;
const RedRightButton = styled(RedButton)`
  margin-left: 30px;
  @media (max-width: 768px) {
    margin-top: 15px;
    margin-left: 0;
  }
`;
interface SubscriptionInfoProps {
  upgradeToPro: () => void;
}
enum SubscriptionModal {
  closed = 0,
  unsubscribe = 1,
  autoRenew = 2,
}

const SubscriptionInfo = (props: SubscriptionInfoProps) => {
  const { t } = useTranslation('Admin');
  const { organization, setOrganization } = useContext(OrganizationContext);
  const [modal, setModal] = useState(SubscriptionModal.closed);
  const { addToast } = useContext(ToastContext);
  const isTrial = organization.subscription.subscriptionLevel === SubscriptionLevel.Trial;
  const history = useHistory();
  const unsubscribe = async () => {
    try {
      const newSubscription = await api.ChangeAutoRenew(false);

      setOrganization({ ...organization, subscription: newSubscription });
      addToast(t('Your subscription will not be renewed, it will expire on ') + moment(organization.subscription.expirationDate).format('DD/MM/YYYY'), toastOptions.success);
    } catch {
      addToast(t('An error occurred while trying to unsubscribe, please try again later or '), { ...toastOptions.error, action: toastOptions.contact });
    }
  };

  const unsubscribeModal = () => {
    return (
      <Modal
        isOpen={modal === SubscriptionModal.unsubscribe}
        onRequestClose={() => setModal(SubscriptionModal.closed)}
        style={modalStyles}
        contentLabel={t('Unsubscribe from TeamReporter')}>
        <ModalTopSection>
          <h3>{t('Unsubscribe from TeamReporter')}</h3>
          <ClickableIconWrapper onClick={() => setModal(SubscriptionModal.closed)}>
            <Close size={'32px'} />
          </ClickableIconWrapper>
        </ModalTopSection>
        <p>{t('Are you sure you want to unsubscribe from TeamReporter? ')}</p>
        <ModalButtonWrapper>
          <Button variant={'secondary'} onClick={() => setModal(SubscriptionModal.closed)}>
            {t('Cancel')}
          </Button>
          <RedButton
            onClick={async () => {
              await unsubscribe();
              setModal(SubscriptionModal.closed);
            }}>
            {t('Unsubscribe')}
          </RedButton>
        </ModalButtonWrapper>
      </Modal>
    );
  };

  return (
    <InfoWrapper>
      {unsubscribeModal()}
      <h1>{t('Subscription')}</h1>
      <h2>{t('Subscription Details')}</h2>
      <h3>{t('Type')}</h3>
      {isTrial && (
        <>
          <p>{t('Free trial')}</p>
          <h3>{t('End date')}</h3>
          <p>{moment(organization.subscription.expirationDate).format('LL')}</p>
          <Button style={{ marginTop: '50px' }} size={'big'} onClick={props.upgradeToPro}>
            {t('Convert to Pro')}
          </Button>
        </>
      )}
      {!isTrial && (
        <>
          <p>{t('Pro') + ' (' + organization.subscription.subscriptionSize + ' ' + t('user accounts') + ')'}</p>
          <h3>{organization.subscription.autoRenew ? t('Renewal date') : t('Expiration date')}</h3>
          <p>{moment(organization.subscription.expirationDate).format('LL')}</p>
          <SubscriptionManagementWrapper>
            <p>{t('Manage your subscription')}</p>
            <BigButtonWrapper>
              <Button variant="secondary" size="big" onClick={() => history.push('/request-extra-instructors')}>
                {t('Request extra instructors')}
              </Button>
              {organization.subscription.autoRenew && (
                <RedRightButton size="big" variant="primary" onClick={() => setModal(SubscriptionModal.unsubscribe)}>
                  {t('Unsubscribe')}
                </RedRightButton>
              )}
            </BigButtonWrapper>
          </SubscriptionManagementWrapper>
        </>
      )}
    </InfoWrapper>
  );
};

export default SubscriptionInfo;
