import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { LandingCenteredBanner } from '../../typings/sanity';
import { useHistory } from 'react-router-dom';
import { Button, COLORS } from '@laerdal/life-react-components';

const TrialSectionWrapper = styled.section`
  /* border-top: 1px solid ${COLORS.neutral_500}; */
  width: 100%;
  height: 400px;
  background-color: ${COLORS.accent1_100};
  text-align: center;
  padding: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  & h2 {
    color: ${COLORS.black};
    margin-top: 0;
    font-size: 48px;
    font-weight: 300;
  }
  & p {
    color: ${COLORS.black};
    margin-bottom: 40px;
  }
`;

interface TrialSectionProps {
  content: LandingCenteredBanner;
}

const TrialSection = (props: TrialSectionProps) => {
  const history = useHistory();
  return (
    <TrialSectionWrapper>
      <div>
        <h2>{props.content.title}</h2>
        <p>{props.content.description}</p>
        <Button size="big" onClick={() => history.push('/plans-and-pricing')}>
          {props.content.button.buttonText}
        </Button>
      </div>
    </TrialSectionWrapper>
  );
};

export default TrialSection;
