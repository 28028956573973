// This is the Organization info displayed in the admin panel. The type OrganizationDetails is for the create organization form, defined in the OrganizationDetails context.
export interface Organization {
  id: string;
  name: string;
  domain: string;
  billingName: string;
  address: Address;
  billingAddress: Address;
  email: string;
  industry: Industry;
  subscription: Subscription;
  members: OrganizationMember[];
  vatNumber: string;
  primaryContactPerson: string;
  useSameAddress: boolean;
}
export interface EditableOrganization {
  name: string;
  billingName: string;
  domain: string;
  industry: Industry;
  address: EditAddress;
  billingAddress: EditAddress;
  email: string;
  primaryContactPerson: string;
  vatNumber: string;
  useSameAddress: boolean;
}
export interface OrganizationMember {
  id?: string;
  email: string;
  firstName: string;
  lastName: string;
  appAccess?: AppAccess;
}
export enum AppAccess {
  Active = 1,
  InActive = 2,
  Deleted = 3,
}
export interface Address {
  addressLine: string;
  zipCode: string;
  country: OrgCountry;
  state: string;
  city: string;
}
export interface EditAddress {
  addressLine: string;
  zipCode: string;
  country: Country;
  state: State;
  city: string;
}

export interface OrgCountry {
  name: string;
  codeAlpha2: string;
  codeAlpha3?: string;
}

export interface Country {
  name: string;
  codeAlpha2: string;
  codeAlpha3?: string;
  states: State[];
}
export interface State {
  name: string;
  code: string;
}

export enum Industry {
  Healthcare,
  EducationalProvider,
  EmergencyMedicalService,
  GovFederalAndMilitary,
  Other,
  null,
}

export interface User {
  isAuthenticated: boolean;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  language: string;
  userFetchComplete: boolean;
}

export interface PriceValues {
  freeTrial: string;
  oneMonth: string;
  oneYear: string;
  threeYears: string;
  fiveYears: string;
}

export interface Subscription {
  subscriptionLevel: SubscriptionLevel;
  subscriptionLength: SubscriptionLength;
  subscriptionSize: number;
  autoRenew: boolean;
  expirationDate?: string;
  isExpired: boolean;
  organizationHasBeenUpdated?: boolean;
}

export enum SubscriptionLevel {
  Trial = 0,
  Professional = 1,
}

export enum SubscriptionLength {
  none = 0,
  ThirtyDays = 30,
  OneYear = 1,
  ThreeYears = 3,
  FiveYears = 5,
}

export interface Order {
  name: string;
  domain: string;
  address: Address;
  billingName: string;
  billingAddress?: Address;
  useSameAddress: boolean;
  email: string;
  primaryContactPerson: string;
  subscription: Subscription;
  industry: Industry;
  vatNumber: string;
}

export interface TrialOrder {
  name: string;
  domain: string;
  industry: Industry;
  address: {
    country: OrgCountry;
    state: string;
  };
  subscription: Subscription;
}

// Types for Global header component
export interface Product {
  id: string;
  name: string;
  type: string;
  image: string;
}

export interface NavOption {
  label: string;
  to: string;
  disabled?: boolean;
}

export interface NavButton {
  label: string;
  action: any;
  disabled?: boolean;
  isSignIn?: boolean;
}

export interface ActionOptions {
  search?: () => void;
  apps?: () => void;
  language?: () => void;
  notification?: () => void;
  settings?: () => void;
  user?: () => void;
}

export class NotFoundError extends Error {
  // tslint:disable-line:export-name
  constructor(message?: string) {
    super(message); // 'Error' breaks prototype chain here
    const target: typeof NotFoundError = new.target;
    Object.setPrototypeOf(this, target.prototype); // restore prototype chain // tslint:disable-line:no-unsafe-any
  }
}

export interface Price {
  currency: string;
  value: number;
  ISOCode: string;
  languageCountryCode: string;
}

//Auth
export interface GigyaJwt {
  callId: string;
  context: string;
  errorCode: number;
  apiVersion: number;
  statusCode: number;
  statusReason: string;
  time: string;
  ignoredFields: string;
  id_token: string;
}

export interface organizationType {
  country: string;
}

export interface dataType {
  organization: organizationType;
}

export interface profileType {
  firstName: string;
  lastName: string;
  email: string;
}

export interface AccountInfo {
  callId: string;
  context: string;
  errorCode: number;
  apiVersion: number;
  statusCode: number;
  statusReason: string;
  time: string;
  registeredTimestamp: number;
  UID: string;
  UIDSignature: string;
  signatureTimestamp: string;
  created: string;
  createdTimestamp: number;
  data: dataType;
  isActive: boolean;
  isRegistered: boolean;
  isVerified: boolean;
  lastLogin: string;
  lastLoginTimestamp: number;
  lastUpdated: string;
  lastUpdatedTimestamp: number;
  loginProvider: string;
  oldestDataUpdated: string;
  oldestDataUpdatedTimestamp: number;
  profile: profileType;
  registered: string;
  socialProviders: string;
}

export interface LogoutResponse {
  connectedProviders: string;
  UID: string;
  logoutActiveSession: boolean;
  callId: string;
  context: string;
  errorCode: number;
  apiVersion: number;
  statusCode: number;
  statusReason: string;
  time: string;
}

export enum ContactType {
  GeneralComment = 0,
  Feedback = 1,
  ReportProblem = 2,
  Enterprise = 3,
}

export interface ContactUsForm {
  country: OrgCountry;
  email: string;
  messageType: ContactType;
  instructorCount: string;
  manikins: string;
  wantPredictiveMaintenance: boolean;
  message: string;
}
