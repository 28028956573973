import React from 'react';
import styled from 'styled-components';
import { LandingWideSection } from '../../typings/sanity';
import { COLORS, ChevronRight } from '@laerdal/life-react-components';

interface WideSectionWrapperProps {
  backgroundImage: string;
}

const WideSectionWrapper = styled.section<WideSectionWrapperProps>`
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  width: 100%;
  min-height: 600px;
  padding: 60px;
  box-sizing: border-box;
  @media (max-width: 700px) {
    padding: 20px 0;
  }
`;

const WideSectionContent = styled.div`
  width: 500px;
  margin: 0 18% 0 auto;
  @media (max-width: 700px) {
    margin: 0;
    width: 100%;
  }
`;
const WideSectionText = styled.div`
  background-color: rgb(50, 50, 50, 0.6);
  color: ${COLORS.white};
  padding: 40px;
  & h1 {
    font-size: 48px;
  }
  & a {
    color: ${COLORS.white};
    text-decoration: none;
  }
  & p {
    line-height: 30px;
  }
`;
const WideSectionLogos = styled.div`
  margin-top: 20px;
  padding: 10px 0;
  display: flex;
  justify-content: space-around;
  background-color: rgb(50, 50, 50, 0.6);
`;
const Logo = styled.img`
  max-height: 100px;
  object-fit: contain;
`;

interface WideSectionProps {
  wideSectionContentSanity: LandingWideSection;
}

const WideSection = (props: WideSectionProps) => {
  return (
    <WideSectionWrapper backgroundImage={props.wideSectionContentSanity.backgroundImage}>
      <WideSectionContent>
        <WideSectionText>
          <h1>{props.wideSectionContentSanity.title}</h1>
          <p>{props.wideSectionContentSanity.description}</p>
          <p>
            <a href={props.wideSectionContentSanity.linkUrl} target="_blank">
              {props.wideSectionContentSanity.linkText}
            </a>
            <ChevronRight size="15px" />
          </p>
        </WideSectionText>
        <WideSectionLogos>
          <Logo src={props.wideSectionContentSanity.firstLogoImage} alt="" />
          <Logo src={props.wideSectionContentSanity.secondLogoImage} alt="" />
        </WideSectionLogos>
      </WideSectionContent>
    </WideSectionWrapper>
  );
};

export default WideSection;
