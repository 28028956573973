import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import OrganizationContext from '../../contexts/OrganizationContext';
import { SubscriptionLevel } from '../../typings/types';
import { InfoWrapper } from '../layouts/AdminLayouts';

const OrganizationInfo = () => {
  const { t } = useTranslation('Admin');
  const { organization, countries } = useContext(OrganizationContext);
  const isTrial = organization.subscription.subscriptionLevel === SubscriptionLevel.Trial;

  const getStateName = (countryCode: string, stateCode: string) => {
    const country = countries.find((country) => country.codeAlpha2 === countryCode);
    if (country?.states && country?.states.length > 0) {
      return country?.states.find((state) => state.code === stateCode)?.name;
    }
    return '';
  };

  return (
    <InfoWrapper>
      <h1>{t('Organization Details')}</h1>
      <h2>{t('General')}</h2>
      <h3>{t('Name')}</h3>
      <p>{organization.name}</p>
      <h3>{t('Organization domain')}</h3>
      <p>{organization.domain}</p>
      <h3>{t('Address')}</h3>
      <p>{organization.address?.addressLine}</p>
      <p>{organization.address?.country?.name}</p>
      {organization.address?.state && <p>{getStateName(organization.address.country.codeAlpha2, organization.address.state)}</p>}
      <p>{organization.address?.zipCode}</p>

      {!isTrial && (
        <>
          <h2>{t('Billing details')}</h2>
          <h3>{t('Name')}</h3>
          <p>{organization.billingName}</p>
          <h3>{t('Billing address')}</h3>
          <p>{organization.billingAddress?.addressLine}</p>
          <p>{organization.billingAddress?.country?.name}</p>
          {organization.billingAddress?.state && <p>{getStateName(organization.billingAddress.country.codeAlpha2, organization.billingAddress.state)}</p>}
          <p>{organization.billingAddress?.zipCode}</p>
          <h3>{t('Billing Email Address')}</h3>
          <p>{organization.email}</p>
        </>
      )}
    </InfoWrapper>
  );
};

export default OrganizationInfo;
