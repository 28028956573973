import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { client } from '../services/sanity';
import { useTranslation } from 'react-i18next';
const Title = styled.h1`
  margin: 0;
  font-size: 48px;
  font-weight: 300;
  margin: 50px 0;
`;
const TermsPageWrapper = styled.div`
  padding: 0 10%;
  max-width: 800px;
  margin: 0 auto;
`;

interface marksItemType {}

interface childrenItemType {
  _key: string;
  _type: string;
  marks: Array<marksItemType>;
  text: string;
}

interface markDefsItemType {}

interface textItemType {
  _key: string;
  _type: string;
  children: Array<childrenItemType>;
  markDefs: Array<markDefsItemType>;
  style: string;
}

interface resultItemType {
  _createdAt: string;
  _id: string;
  _rev: string;
  _type: string;
  _updatedAt: string;
  text: Array<textItemType>;
}

const TermsAndConditions = () => {
  const { t } = useTranslation('CreateOrganization');
  const [terms, setTerms] = useState<Array<textItemType>>([]);
  const getTermsAndConditionsFromSanity = () => {
    const query = '*[_type == "termsAndConditions"]';
    client.fetch(query).then((TermsAndConditionsContentFromSanity: Array<resultItemType>) => {
      setTerms(TermsAndConditionsContentFromSanity[0].text);
    });
  };
  useEffect(() => {
    getTermsAndConditionsFromSanity();
  }, []);

  const renderText = () => {
    return (
      <>
        {terms.map((textObject: textItemType) => {
          return <p key={textObject._key}>{textObject.children[0].text}</p>;
        })}
      </>
    );
  };
  return (
    <TermsPageWrapper>
      <Title>{t('Terms and conditions')}</Title>
      {renderText()}
    </TermsPageWrapper>
  );
};

export default TermsAndConditions;
