import axios from 'axios';
import { GigyaJwt } from './typings/types';

axios.defaults.baseURL = process.env.REACT_APP_API;
axios.defaults.headers.patch['Content-Type'] = 'application/json';

export const clearToken = () => {
  axios.defaults.headers.common['Authorization'] = 'Bearer ';
};

const getJWT = () =>
  new Promise<string>((resolve, reject) => {
    // @ts-ignore
    window.gigya.accounts.getJWT({
      fields: 'firstName, lastName, email',
      expiration: process.env.REACT_APP_GIGYA_TOKEN_TIMER,
      callback: (res: GigyaJwt) => {
        if (res.errorCode === 0) {
          resolve(res.id_token);
        } else {
          reject('Unable to get JWT. Not logged in?');
        }
      },
    });
  });

  const urlsWhitelist = ["/assets/countries", '/contactus', '/locations/geoLocation']

axios.interceptors.request.use((request) => {
  //Does nothing if token is already present
  const jwt = axios.defaults.headers.common['Authorization'];
  if (!!jwt && jwt.length > 20) return request;
  if (urlsWhitelist.some(url => url === request.url)) return request;

  //Returns axios config with new token
  return getJWT()
    .then((token) => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      request.headers['Authorization'] = `Bearer ${token}`;
      return Promise.resolve(request);
    })
    .catch((err) => {
      return Promise.reject(err)
    });
});

// Intercepts all API responses. If it's a 401, requests a new JWT and tries again
axios.interceptors.response.use(
  (response) => {
    // Return a successful response back to the calling service
    return response;
  },
  (error) => {
    console.log(error)
    // Return any error which is not due to authentication back to the calling service
    if (error.response.status !== 401) {
      return Promise.reject(error);
    }
    return getJWT()
      .then((token) => {
        // New request with new token
        const config = error.config;
        config.headers['Authorization'] = `Bearer ${token}`;
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return new Promise((resolve, reject) => {
          axios
            .request(config)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      })
      .catch(() => {
        return Promise.reject('Unable to get JWT after 401 response. Not logged in?');
      });
  },
);
