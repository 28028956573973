import { LandingCenteredBanner, LandingFeature } from '../../typings/sanity';
import styled from 'styled-components';
import React from 'react';
import { COLORS } from '@laerdal/life-react-components';

const FeatureSection = styled.section`
  display: flex;
  justify-content: center;
  padding: 60px 0;
  align-items: center;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
    margin-bottom: 30px;
  }
`;

const FeatureIntroWrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px 0;
  & p {
    line-height: 30px;
  }
  & h2 {
    font-size: 32px;
  }
`;
const FeatureIntroContent = styled.div`
  max-width: 1000px;
  text-align: center;
`;

const StandoutFeature = styled(FeatureSection)`
  background-color: ${COLORS.neutral_20};
  margin: 90px 0;
`;

const FeaturesWrapper = styled.div`
  & ${FeatureSection}:nth-child(even) {
    flex-direction: row-reverse;
    @media screen and (max-width: 1100px) {
      flex-direction: column;
    }
  }
`;

const FeatureText = styled.div`
  max-width: 400px;
  padding: 32px;
  & h2 {
    font-weight: 300;
    margin-top: 0;
    font-size: 48px;
  }
  & p {
    line-height: 30px;
  }
`;

const FeatureImg = styled.img`
  width: 600px;
  margin: 25px;
  object-fit: contain;
  @media screen and (max-width: 1100px) {
    width: 100%;
    margin-bottom: 0;
  }
`;

const StandoutFeatureImg = styled(FeatureImg)`
  width: 800px;
`;

const FeatureVideo = styled.video`
  width: 600px;
  padding: 30px;
  max-height: 500px;
  @media screen and (max-width: 1100px) {
    width: 100%;
    margin-bottom: 0;
    padding: 0;
  }
`;

interface FeaturesProps {
  featureSections: LandingFeature[];
  standoutFeature: LandingFeature;
  featureIntro: LandingCenteredBanner;
}

const Features = (props: FeaturesProps) => {
  const featureSectionsToDisplay =
    props.featureSections &&
    props.featureSections.map((featureSection) => (
      <FeatureSection key={featureSection.id}>
        <FeatureText>
          <h2>{featureSection.title}</h2>
          <p>{featureSection.description}</p>
        </FeatureText>
        {!!featureSection.video ? <FeatureVideo autoPlay loop muted src={featureSection.video} /> : <FeatureImg src={featureSection.image} alt="" />}
      </FeatureSection>
    ));
  return (
    <>
      <FeatureIntroWrapper>
        <FeatureIntroContent>
          <h2>{props.featureIntro.title}</h2>
          <p>{props.featureIntro.description}</p>
        </FeatureIntroContent>
      </FeatureIntroWrapper>
      <FeaturesWrapper>
        {featureSectionsToDisplay}
        <StandoutFeature>
          <FeatureText>
            <h2>{props.standoutFeature.title}</h2>
            <p>{props.standoutFeature.description}</p>
          </FeatureText>
          {!!props.standoutFeature.video ? <FeatureVideo autoPlay loop muted src={props.standoutFeature.video} /> : <StandoutFeatureImg src={props.standoutFeature.image} alt="" />}
        </StandoutFeature>
      </FeaturesWrapper>
    </>
  );
};

export default Features;
