import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmOrderSummary from '../components/basket/ConfirmOrderSummary';
import { ButtonWrapper } from '../components/layouts/InputLayouts';
import { Button, ToastContext } from '@laerdal/life-react-components';
import { useHistory } from 'react-router-dom';
import StepCounter from '../components/createOrganization/StepCounter';
import TermsCheckbox from '../components/createOrganization/TermsCheckbox';
import * as api from '../services/api';
import { createTrialOrder } from '../services/functions';
import BasketContext, { defaultBasketTrial } from '../contexts/BasketContext';
import OrganizationDetailsContext from '../contexts/OrganizationDetailsContext';
import OrganizationContext from '../contexts/OrganizationContext';
import toastOptions from '../assets/ToastOptions';
import { PageWrapper, LeftWrapper } from '../components/layouts/OrderFlowLayouts';

const ConfirmOrderTrialPage = () => {
  const history = useHistory();
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const { t } = useTranslation('CreateOrganization');
  const { editOrganization } = useContext(OrganizationDetailsContext);
  const { chosenSubscription, setChosenSubscription } = useContext(BasketContext);
  const { setOrganization } = useContext(OrganizationContext);
  const { addToast } = useContext(ToastContext);

  const confirmOrder = () => {
    api
      .PostOrder(createTrialOrder(editOrganization, chosenSubscription))
      .then((org) => {
        setOrganization({ ...org, subscription: { ...org.subscription, organizationHasBeenUpdated: true } });
        history.push('/order-complete', 'trial');
      })
      .catch((error) => {
        if (error.response.status === 503) {
          api
            .GetOrganization()
            .then((org) => {
              if (!!org) {
                setOrganization(org);
              }
            })
            .catch((error) => {
              addToast(t('Server responded with') + ` (${error?.response?.status}), ` + t(' reload the page or'), {
                ...toastOptions.error,
                action: [
                  {
                    label: 'contact us',
                    handler: () => window.open('https://www.laerdal.com/no/support/customer-service/contact-us/', '_blank'),
                  },
                ],
              });
            });
        }
        addToast(t('Unable to process order') + ` (${error.response.status}), ` + t('please try again later or'), {
          ...toastOptions.error,
          action: toastOptions.contact,
        });
      });
  };

  useEffect(() => {
    setChosenSubscription(defaultBasketTrial);
    !editOrganization.domain && history.push('create-organization-trial');
  }, []);

  return (
    <PageWrapper>
      <LeftWrapper>
        <StepCounter currentStep={2} freeTrial />
        <h1>{t('Confirm your order')}</h1>
        <p>{t('Confirm your order and get immediate access to the TeamReporter app.')}</p>

        <TermsCheckbox agreeToTerms={agreeToTerms} setAgreeToTerms={setAgreeToTerms} />
        <ButtonWrapper>
          <Button variant="secondary" size={'big'} onClick={() => history.push('/create-organization-trial')}>
            {t('Cancel')}
          </Button>
          <Button type="submit" variant="primary" size={'big'} disabled={!agreeToTerms} onClick={confirmOrder}>
            {t('Confirm')}
          </Button>
        </ButtonWrapper>
      </LeftWrapper>
      <ConfirmOrderSummary />
    </PageWrapper>
  );
};

export default ConfirmOrderTrialPage;
