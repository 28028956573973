import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { COLORS, User, Mail } from '@laerdal/life-react-components';

const BillingDetailsWrapper = styled.div`
  border: 1px solid ${COLORS.neutral_200};
  padding: 16px 16px 8px 16px;
  border-radius: 15px;
  & h2 {
    font-weight: 300;
    margin: 0;
  }
  & p {
    font-weight: 300;
    font-size: 16px;
    margin: 8px 0;
  }
`;

const BillingSummaryArticle = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0;
`;

const ArticleTextWrapper = styled.div`
  margin-left: 10px;
  & h3 {
    font-weight: 400;
    font-size: 16px;
    color: ${COLORS.neutral_500};
    margin: 0;
  }
  & p {
    margin: 0;
    font-size: 18px;
  }
`;

interface BillingDetailsSummaryProps {
  billingEmail: string;
  contactPerson: string;
}

const BillingDetailsSummary = (props: BillingDetailsSummaryProps) => {
  const { t } = useTranslation('CreateOrganization');
  return (
    <BillingDetailsWrapper>
      <h2>{t('Billing details')}</h2>
      <p>{t('An invoice shall be processed and sent to:')}</p>
      <BillingSummaryArticle>
        <User size={'32px'} />
        <ArticleTextWrapper>
          <h3>{t('Primary Contact Person')}</h3>
          <p>{props.contactPerson}</p>
        </ArticleTextWrapper>
      </BillingSummaryArticle>
      <BillingSummaryArticle>
        <Mail size={'32px'} />
        <ArticleTextWrapper>
          <h3>{t('Billing Email Address')}</h3>
          <p>{props.billingEmail}</p>
        </ArticleTextWrapper>
      </BillingSummaryArticle>
    </BillingDetailsWrapper>
  );
};

export default BillingDetailsSummary;
