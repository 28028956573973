import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import EditSubscriptionStepOne from '../components/admin/EditSubscriptionStepOne';
import RequestExtraInstructorsBasket from '../components/basket/RequestExtraInstructorsBasket';
import { PageWrapper } from '../components/layouts/OrderFlowLayouts';
import BasketContext from '../contexts/BasketContext';
import OrganizationContext from '../contexts/OrganizationContext';

const RequestExtraInstructors = () => {
  const { t } = useTranslation('CreateOrganization');
  const { organization } = useContext(OrganizationContext);
  const { chosenSubscription, setChosenSubscription, requestPageOneComplete } = useContext(BasketContext);

  const EditSubscription = {
    header: t('Request extra instructors'),
    description: t(
      'It looks like you’re requesting extra instructors in your plan. Please enter the required details before proceeding. The system will calculate your extra costs automatically.',
    ),
    linkUrl: '/confirm-extra-instructors',
    requestExtraInstructors: true,
  };

  useEffect(() => {
    !requestPageOneComplete &&
      setChosenSubscription({
        ...chosenSubscription,
        subscriptionLength: organization.subscription.subscriptionLength,
      });
  }, []);

  return (
    <PageWrapper>
      <EditSubscriptionStepOne {...EditSubscription} />
      <RequestExtraInstructorsBasket />
    </PageWrapper>
  );
};

export default RequestExtraInstructors;
